import AppDatas from '../utils/AppDatas';
import AssetsLoader from '../utils/AssetsLoader';
import GraphicsUtils from '../utils/GraphicsUtils';
import RndUtils from '../utils/RndUtils';
import ImageParticle from './effects/ImageParticle';
import 'pixi-filters';

export default class RumeurView {

	constructor() {

		this.percent = 0;

        this.init();
        this.initInteraction();
        
	}

	init(){

		this.cont = new PIXI.Container();
		this.cont.alpha=0;
		AppDatas.stage.addChild(this.cont);

		this.bg_texture = AppDatas.assets_loader.loader.resources.rumeur_bg.texture
		this.bg_sp = new PIXI.Sprite(this.bg_texture);
		this.bg_sp.anchor.x = this.bg_sp.anchor.y =.5;
		this.bg_sp.x = AppDatas.GAME_WIDTH * .5
		this.bg_sp.y = AppDatas.GAME_HEIGHT * .5
		this.cont.addChild(this.bg_sp);

		this.bulles_ar = new Array();
		this.pos_ar = new Array({x:500,y:600},{x:550,y:950},{x:400,y:350},{x:1500,y:250});

		for (var i = 0; i < 4; i++) {
			// console.log("rumeur_bulle"+((i%4)+1))
			this.bulle_texture = AppDatas.assets_loader.loader.resources["rumeur_bulle"+((i%4)+1)].texture
			this.bulle_sp = new PIXI.Sprite(this.bulle_texture);
			this.bulle_sp.x = this.pos_ar[i].x;
			this.bulle_sp.y = this.pos_ar[i].y;
			this.bulle_sp.alpha=0;
			this.cont.addChild(this.bulle_sp);

			this.bulles_ar.push(this.bulle_sp)
		}


		this.bg_gr = new PIXI.Graphics();
		this.bg_gr.beginFill(0x000000, .6);
		this.bg_gr.drawRect(0, 0, AppDatas.GAME_WIDTH, AppDatas.GAME_HEIGHT);
		this.bg_gr.endFill();
		this.bg_gr.alpha=0;
		this.cont.addChild(this.bg_gr);

		this.animIn();
	}


	animIn(){

		this.dp1_texture = AppDatas.assets_loader.loader.resources.dpmap1.texture
        this.dp1_sp = new PIXI.Sprite(this.dp1_texture);
        AppDatas.stage.addChild(this.dp1_sp);

        this.dp1_sp.width = this.cont.width;
        this.dp1_sp.height = this.cont.height;

        this.displacementFilter = new PIXI.filters.DisplacementFilter( this.dp1_sp );
  		this.displacementFilter.scale.x = 100
  		this.displacementFilter.scale.y = 1

  		this.cont.filters = [
			this.displacementFilter,
		];


		TweenMax.to(this.cont, 1, {alpha:1, ease:Power1.easeIn});
		TweenMax.to(this.displacementFilter.scale, 3, { x: 1, y: 1, ease: Power2.easeOut, onComplete: () => this.onSceneReady()})


	}

	initInteraction(){

		this.dragSize = 600;
		this.arrow_x = AppDatas.GAME_WIDTH * .5 - this.dragSize* .5;
		this.circle_x = AppDatas.GAME_WIDTH * .5 + this.dragSize* .5;

		this.circle_texture = AppDatas.assets_loader.loader.resources.circle.texture
		this.circle_sp = new PIXI.Sprite(this.circle_texture);

		this.circle_sp.anchor.x = this.circle_sp.anchor.y =.5;
		this.circle_sp.x=this.circle_x;
		this.circle_sp.y=1200;

		this.arrow_texture = AppDatas.assets_loader.loader.resources.arrow.texture
		this.arrow_sp = new PIXI.Sprite(this.arrow_texture);
		this.arrow_sp.name = "arrow";
	
		this.arrow_sp.anchor.x = this.arrow_sp.anchor.y =.5;
		this.arrow_sp.x=this.arrow_x;
		this.arrow_sp.y=1200;
		// this.arrow_sp.rotation = -90 * Math.PI / 180;

		this.line_cont = new PIXI.Container();
		this.cont.addChild(this.line_cont);

		this.line_cont.addChild( GraphicsUtils.drawdash(this.arrow_sp.x+(this.arrow_sp.width*.5 + 15) , this.arrow_sp.y, this.circle_sp.x-(this.circle_sp.width*.5 + 15), this.circle_sp.y, 2) );

		this.cont.addChild(this.circle_sp);
		this.cont.addChild(this.arrow_sp);

		this.arrow_sp.alpha=0;
		this.circle_sp.alpha=0;
		this.line_cont.alpha=0;

		this.arrow_sp.interactive = true;
		this.arrow_sp.buttonMode = true;

		this.pointerdownMethod = (e) => this.onArrowDown(e)
		this.pointerupMethod = (e) => this.onArrowUp(e)
		this.pointerMoveMethod = (e) => this.onArrowMove(e)
		
	}

	onSceneReady(){
		this.arrow_sp.on("pointerdown", this.pointerdownMethod);
		this.arrow_sp.on("pointerupoutside", this.pointerupMethod);
		this.arrow_sp.on("pointerup", this.pointerupMethod);

		TweenMax.to(this.bg_gr, .3, {alpha:1, delay:0, ease:Power1.easeOut});

		TweenMax.set(this.circle_sp.scale, {x:.8, y:.8});
		TweenMax.to(this.circle_sp, .3, {alpha:1, delay:.3, ease:Power1.easeOut});

		TweenMax.to(this.circle_sp.scale, 1, {x:1, y:1, delay:.6, ease:Back.easeOut.config(3)});
		
		TweenMax.to(this.arrow_sp, .3, {alpha:1, delay:1.3, ease:Power1.easeOut});
		this.arrow_sp.x=this.arrow_x+300;
		TweenMax.to(this.arrow_sp, .5, {x:this.arrow_x, delay:1.5, ease:Power2.easeOut});

		TweenMax.to(this.line_cont, .3, {alpha:1, delay:1.5, ease:Power1.easeOut});

		TweenMax.to(this.bg_gr, .3, {alpha:0, delay:2.5, ease:Power1.easeOut});

	}

	onArrowDown(e){
		this.arrow_sp.on("pointermove", this.pointerMoveMethod);
		
	}


	onArrowMove(e){

		let xmouse = e.data.global.x / AppDatas.stage.scale.x;
		
		if(xmouse > this.arrow_x  && xmouse < this.circle_x ){
			this.arrow_sp.x = e.data.global.x / AppDatas.stage.scale.x;
		}

		this.percent = (Math.round(this.arrow_sp.x-this.arrow_x)/this.dragSize);
		// console.log(this.percent)

		this.updatePos()
	}

	updatePos(){

		TweenMax.to(this.bulles_ar[0], .5, { alpha: this.percent-.2, ease: Power2.easeOut})
		TweenMax.to(this.bulles_ar[1], .5, { alpha: this.percent*2, ease: Power2.easeOut})
		TweenMax.to(this.bulles_ar[2], .5, { alpha: this.percent-.3, ease: Power2.easeOut})
		TweenMax.to(this.bulles_ar[3], .5, { alpha: this.percent-.4, ease: Power2.easeOut})


	// 	TweenMax.to(this.street_sp.scale, 2, { x: 1 + (.05*this.percent), y: 1 + (.05*this.percent), ease: Power2.easeOut})
	// 	TweenMax.to(this.street_sp, 2, { x: AppDatas.GAME_WIDTH * .5 - (45*this.percent), y:AppDatas.GAME_HEIGHT * .5 + (30*this.percent), ease: Power2.easeOut})

		// TweenMax.to(this.zoomFilter, .8, { strength: this.percent*.07, ease: Power2.easeOut})
	}

	updatePosRelease(){

		this.percent = (Math.round(this.arrow_sp.x-this.arrow_x)/this.dragSize);
		this.updatePos();

	}

	onArrowUp(e){
		// console.log("up", this.arrow_sp.y)
		this.arrow_sp.off("pointermove", this.pointerMoveMethod);

		if(this.arrow_sp.x >= this.circle_x-100){
			TweenMax.to(this.arrow_sp, .3, {y:this.circle_sp.y, x:this.circle_sp.x, ease:Power3.easeOut});
			TweenMax.to(this.line_cont, .3, {alpha:0, ease:Power1.easeOut});
			TweenMax.set(this.circle_sp, {alpha:0, delay:.2});
			TweenMax.to(this.arrow_sp, .3, {alpha:0, delay:.2, ease:Power1.easeOut});

			this.arrow_sp.off("pointerdown", this.pointerdownMethod);
			this.arrow_sp.off("pointerupoutside", this.pointerupMethod);
			this.arrow_sp.off("pointerup", this.pointerupMethod);

			this.addBulles()

		}else{
			TweenMax.to(this.arrow_sp, .5, {y:1200, x:this.arrow_x, ease:Power3.easeOut, onUpdate: () => this.updatePosRelease()});

		}
	

	}

	addBulles(){
		for (var i = 0; i < 10; i++) {
			let bulle_texture = AppDatas.assets_loader.loader.resources["rumeur_bulle"+((i%4)+1)].texture
			let bulle_sp = new PIXI.Sprite(bulle_texture);
			bulle_sp.x = RndUtils.randomIntFromInterval(0, AppDatas.GAME_WIDTH);
			bulle_sp.y = RndUtils.randomIntFromInterval(0, AppDatas.GAME_HEIGHT*.5,);
			bulle_sp.alpha=0;
			TweenMax.set(bulle_sp, { alpha: 1, delay:.1*i, ease: Power2.easeOut})
			this.cont.addChild(bulle_sp);

		}

		setTimeout(() => this.animOut(), 800); 
	}

	animOut(){

		this.cont.filters = [
			this.displacementFilter,
		];


		TweenMax.to(this.displacementFilter.scale, 4, { x: 100, y: 500, delay:1, ease: Power2.easeIn, onComplete: () => this.destroy()})
		TweenMax.to(this.cont, 2.5, { alpha: 0, delay:2, ease: Power1.easeIn })
	}

	destroy(){

		this.cont.destroy({children:true, texture:false, baseTexture:true});
		AppDatas.appView.navigateTo("temoignages_slides");

	}

}
