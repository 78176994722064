import AppDatas from '../utils/AppDatas';
import AssetsLoader from '../utils/AssetsLoader';
import LocaleUtils from '../utils/LocaleUtils';

export default class TextView {

	constructor(id ,isBg=true) {

		this.id = id;
		this.datas_ar = LocaleUtils.getSlides(this.id);
		this.currentText;
		this.currentTextId = 0;
		this.text_ar = [];
		this.dots_ar = [];

		this.startX = 0;
		this.endX = 0;

		this.startTime = 0;
		this.endTime = 0;
		this.isBg = isBg;

		// console.log(this.datas_ar);


        this.init();
        
	}


	init(){

		// MultiStyleText.debugOptions.spans.enabled = true;
		// MultiStyleText.debugOptions.objects.enabled = true;

		this.cont = new PIXI.Container();
		AppDatas.stage.addChild(this.cont);

		this.bg_texture = AppDatas.assets_loader.loader.resources.textbg.texture
        this.bg_sp = new PIXI.Sprite(this.bg_texture);
        this.bg_sp.alpha = 0;
        if(this.isBg) {
        	this.cont.addChild(this.bg_sp);
        }

		if(this.datas_ar.length > 1 ) this.buildPagination();
		this.buildTexts();


		let next_texture = AppDatas.assets_loader.loader.resources.textnext.texture
		this.next_bt = new PIXI.Sprite(next_texture);
		this.next_bt.x = AppDatas.GAME_WIDTH-300;
		this.next_bt.y = AppDatas.GAME_HEIGHT-100;
		this.next_bt.anchor.x=this.next_bt.anchor.y=.5;
		this.next_bt.scale.x=this.next_bt.scale.y=.5;

		this.next_bt.interactive = true;
		this.next_bt.buttonMode = true;

		this.cont.addChild(this.next_bt);

		this.next_bt.on("pointerup", (e) => this.onNext(e));

		TweenMax.to(this.bg_sp, 1, {alpha:1, delay:.5, ease:Power0.easeNone});

		this.bgback_gr = new PIXI.Graphics();
		this.bgback_gr.beginFill(0x000000, .75);
		this.bgback_gr.drawRect(0, 0, AppDatas.GAME_WIDTH, AppDatas.GAME_HEIGHT);
		this.bgback_gr.endFill();
		this.bgback_gr.alpha=0;
		this.cont.addChild(this.bgback_gr);
		this.bgback_gr.on("pointerup", (e) => this.onCloseDef(e));

		this.bg_gr = new PIXI.Graphics();
		this.bg_gr.beginFill(0xffffff, .95);
		this.bg_gr.drawRect(0, 0, AppDatas.GAME_WIDTH, AppDatas.GAME_HEIGHT);
		this.bg_gr.endFill();
		this.bg_gr.alpha=0;
		this.cont.addChild(this.bg_gr);

	}

	buildTexts(){

		this.text_cont = new PIXI.Container();
		this.text_cont.interactive = true;
		this.cont.addChild(this.text_cont);	
		this.totalSlides = this.datas_ar.length;

		let i=0;

		for (let obj of this.datas_ar) {

			let text = new MultiStyleText(this.datas_ar[i], new PIXI.TextStyle(AppDatas.slidestyle));

			// this.currentText = text;

			text.x=AppDatas.GAME_WIDTH * .5 - text.width *.5;
			text.y= (AppDatas.GAME_HEIGHT * .5 - text.height *.5) - 100//200 / AppDatas.stage.scale.x;

			text.accessible = true;
			text.interactive = true;
			text.accessibleTitle = this.datas_ar[i];
			text.accessibleHint = this.datas_ar[i];

			text.visible = false;
			text.alpha = 0;

			text.on("pointerup", (e) => this.onWordClick(e));
			// text.on("mousemove", (e) => this.onWordOver(e));

			this.text_cont.addChild(text);

			this.text_ar.push(text);

			i++;

		}

		this.text_ar[this.currentTextId].alpha = 1;
		this.text_ar[this.currentTextId].visible = true;

		this.pointerdownMethod = (e) => this.onPointerDown(e)
		this.pointerupMethod = (e) => this.onPointerUp(e)

		if(this.datas_ar.length > 1 ) this.text_cont.on("pointerdown", this.pointerdownMethod);

		let graphics = new PIXI.Graphics();
		graphics.beginFill(0xffffff, 0);
		graphics.drawRect(0, 0, AppDatas.GAME_WIDTH, AppDatas.GAME_HEIGHT-150);
		graphics.endFill();

		this.text_cont.alpha=0;

		this.text_cont.addChild(graphics);

		this.dp2_texture = AppDatas.assets_loader.loader.resources.dpmap2.texture
        this.dp2_sp = new PIXI.Sprite(this.dp2_texture);
    	this.text_cont.addChild(this.dp2_sp);

        this.dp2_sp.width = this.text_cont.width;
        this.dp2_sp.height = this.text_cont.height;

        this.displacementFilter = new PIXI.filters.DisplacementFilter( this.dp2_sp );
  		this.displacementFilter.scale.x = 30
  		this.displacementFilter.scale.y = 1

  		this.text_cont.filters = [
			this.displacementFilter
		];

		TweenMax.to(this.displacementFilter.scale, 2, { x: 1, y: 1, ease: Power1.easeOut })
		TweenMax.to(this.text_cont, 1.5, { alpha: 1, ease: Power1.easeOut })

	}

	buildPagination(){

		this.dots_cont = new PIXI.Container();

		let i=0;

		for (let obj of this.datas_ar) {

			let dot_texture = AppDatas.assets_loader.loader.resources.dot1.texture
			let dot_sp = new PIXI.Sprite(dot_texture);
			dot_sp.x = i* (30 + dot_sp.width);
			dot_sp.anchor.x=dot_sp.anchor.y=.5;
			dot_sp.name = "dot"+i;

			let hitAreaWidth = dot_sp.width*2;
			let hitAreaHeight = dot_sp.height*2;
			dot_sp.hitArea = new PIXI.Rectangle(-hitAreaWidth*.5, -hitAreaHeight*.5, hitAreaWidth, hitAreaHeight);
			
			dot_sp.interactive = true;
			dot_sp.buttonMode = true;

			this.dots_cont.addChild(dot_sp);

			this.dots_ar.push(dot_sp);

			dot_sp.on("pointerup", (e) => this.onDotClick(e));
			dot_sp.on("pointerover", (e) => this.onDotOver(e));
			dot_sp.on("pointerout", (e) => this.onDotOver(e));

			i++;

		}

		this.dots_cont.x = AppDatas.GAME_WIDTH * .5 - this.dots_cont.width *.5;
		this.dots_cont.y = AppDatas.GAME_HEIGHT - 100;

		this.cont.addChild(this.dots_cont);

		this.updateDot();

	}

	onWordOver(e){
		if(e.targetTag){

			if (e.targetTag.name === "link") {
				this.currentText.buttonMode = true;
			}else{
				this.currentText.buttonMode = false;
			}
		}
		
	}

	onWordClick(e){
		if(e.targetTag){
			if (e.targetTag.name === "link") {
				// alert(`You clicked on the link for "${e.targetTag.properties.name}"!`);

				let id = e.targetTag.properties.name;
				// console.log(id, LocaleUtils.getString("lexique",id));

				let def = LocaleUtils.getString("lexique",id);
				this.showDef(def);

				
			}
		}
	}

	showDef(pText){
		var style = new PIXI.TextStyle(AppDatas.defstyle);
		if(AppDatas.isTablet){
			style.fontSize = 40;
			style.lineHeight = 70;
		}
		
		this.deftext = new PIXI.Text(pText, style);
		this.cont.addChild(this.deftext);
		this.deftext.x=AppDatas.GAME_WIDTH * .5 - this.deftext.width *.5;
		this.deftext.y= AppDatas.GAME_HEIGHT * .5 - this.deftext.height *.5;

		this.bg_gr.x = this.deftext.x - 20;
		this.bg_gr.y = this.deftext.y - 20;
		this.bg_gr.width = this.deftext.width + 40;
		this.bg_gr.height = this.deftext.height + 40;

		TweenMax.to(this.bg_gr, .5, { alpha: 1, ease: Power1.easeOut })
		TweenMax.to(this.bgback_gr, .5, { alpha: 1, ease: Power1.easeOut })

		this.bgback_gr.interactive = true;
		this.bgback_gr.buttonMode = true;
	}

	onCloseDef(e){
		this.cont.removeChild(this.deftext);
		TweenMax.to(this.bg_gr, .5, { alpha: 0, ease: Power1.easeOut })
		TweenMax.to(this.bgback_gr, .5, { alpha: 0, ease: Power1.easeOut })

		this.bgback_gr.interactive = false;
		this.bgback_gr.buttonMode = false;

	}

	onDotClick(e){
		// console.log(parseInt(e.currentTarget.name.substr(3)));

		let prevText = this.text_ar[this.currentTextId]
		TweenMax.to(this.text_ar[this.currentTextId], .7, {alpha:0, ease:Power3.easeOut, onComplete: () => prevText.visible = false});
		this.currentTextId = parseInt(e.currentTarget.name.substr(3));
		let nextText = this.text_ar[this.currentTextId]
		TweenMax.to(this.text_ar[this.currentTextId], .7, {alpha:1, ease:Power3.easeIn, onStart: () => nextText.visible = true});

		this.updateDot();
	}

	onDotOver(e){
		switch(e.type) { 
		   case "pointerover": { 
				this.dotanim = TweenMax.to(e.currentTarget.scale, 1, {x:1.2, y:1.2, ease:Elastic.easeOut});
				
				break; 
		   } 
		   case "pointerout": { 
				this.dotanim = TweenMax.to(e.currentTarget.scale, 1, {x:1, y:1, ease:Elastic.easeOut});
		      break; 
		   }
		   default: { 
				break;              
		   } 
		} 
	}

	onPointerDown(e){
		// console.log("onPointerDown", e)

		this.startX = e.data.global.x;
		this.startTime  = e.data.originalEvent.timeStamp;

		this.text_cont.on("pointerup", this.pointerupMethod);
	}

	onPointerUp(e){
		// console.log("onPointerUp", e)

		this.text_cont.off("pointerup", this.pointerupMethod);

		this.endX = e.data.global.x;
		let diff = this.endX - this.startX;

		this.endTime  = e.data.originalEvent.timeStamp;
		let swipeduration = this.endTime - this.startTime;

		// console.log("diff", diff, swipeduration)

		if(Math.abs(diff) > 100 && swipeduration < 500){
			// console.log("SWIPE")
			this.onSwipe(diff);
		}
		
	}

	onSwipe(pDIff_num){

		if(pDIff_num>0){
			// console.log("right")

			if((this.currentTextId - 1)>=0){
				// this.text_ar[this.currentTextId].visible = false;
				let prevText = this.text_ar[this.currentTextId]
				TweenMax.to(this.text_ar[this.currentTextId], .5, {alpha:0, ease:Power3.easeOut, onComplete: () => prevText.visible = false});
				this.currentTextId--;
				let nextText = this.text_ar[this.currentTextId]
				TweenMax.to(this.text_ar[this.currentTextId], .5, {alpha:1, ease:Power3.easeIn, onStart: () => nextText.visible = true});

				this.updateDot();

			}

		}else{
			// console.log("left")

			if((this.currentTextId + 1)<this.text_ar.length){
				// this.text_ar[this.currentTextId].visible = false;
				let prevText = this.text_ar[this.currentTextId]
				TweenMax.to(this.text_ar[this.currentTextId], .5, {alpha:0, ease:Power3.easeOut, onComplete: () => prevText.visible = false});
				this.currentTextId++;
				let nextText = this.text_ar[this.currentTextId]
				TweenMax.to(this.text_ar[this.currentTextId], .5, {alpha:1, ease:Power3.easeIn, onStart: () => nextText.visible = true});

				this.updateDot();
			}
		}

	}

	updateDot(){

		let i = 0;
		let dot_texture = AppDatas.assets_loader.loader.resources.dot1.texture
		

		for (let obj of this.datas_ar) {

			// TweenMax.killTweensOf(this.dots_ar[i]);
			if(this.dotanim) this.dotanim.kill();

			// this.dots_ar[i].alpha = .4;
			this.dots_ar[i].scale.x = this.dots_ar[i].scale.y = 1;
			this.dots_ar[i].interactive = true;
			this.dots_ar[i].texture = dot_texture;
			i++;

		}
		this.dots_ar[this.currentTextId].alpha = 1;
		this.dots_ar[this.currentTextId].interactive = false;

		let dotfull_texture = AppDatas.assets_loader.loader.resources.dot2.texture
		this.dots_ar[this.currentTextId].texture = dotfull_texture;

		this.dotanim = TweenMax.to(this.dots_ar[this.currentTextId].scale, 1, {x:1.2, y:1.2, ease:Elastic.easeOut});
	}

	onNext(e){

		if((this.currentTextId+1) == this.totalSlides){
			this.next_bt.interactive = false;
			TweenMax.to(this.displacementFilter.scale, 1, { x: 10, y: -10, ease: Power1.easeOut })
			TweenMax.to(this.next_bt, 1, { alpha:0, ease: Power1.easeOut })
			TweenMax.to(this.text_cont, .9, { alpha: 0, ease: Power1.easeIn, onComplete: () => this.destroy() })
			TweenMax.to(this.bg_sp, 1, {alpha:0, ease:Power0.easeNone});
			if(this.dots_cont){
				TweenMax.to(this.dots_cont, 1, { alpha:0, ease: Power1.easeOut })
			}

		}else{
			let prevText = this.text_ar[this.currentTextId]
			TweenMax.to(this.text_ar[this.currentTextId], .7, {alpha:0, ease:Power3.easeOut, onComplete: () => prevText.visible = false});
			this.currentTextId ++;
			let nextText = this.text_ar[this.currentTextId]
			TweenMax.to(this.text_ar[this.currentTextId], .7, {alpha:1, ease:Power3.easeIn, onStart: () => nextText.visible = true});
			this.updateDot();
		}

		// this.destroy();
		
	}

	destroy(){
		this.cont.destroy();
		switch(this.id) { 
			case "intro_slides": { 
				// AppDatas.appView.navigateTo("intro");
				AppDatas.appView.introView.showTips();
				
				break; 
			} 
			case "famille_slides": { 
				AppDatas.appView.navigateTo("famille");
				break; 
			}
			case "drame_slides": { 
				AppDatas.appView.navigateTo("drame");
				break; 
			}
			case "enquete_slides": { 
				AppDatas.appView.navigateTo("enquete");
				break; 
			}
			case "rumeur_slides": { 
				AppDatas.appView.navigateTo("rumeur");
				break; 
			}
			case "temoignages_slides": { 
				AppDatas.appView.navigateTo("temoignages");
				break; 
			}
			case "condamnation_slides": { 
				AppDatas.appView.navigateTo("condamnation");
				break; 
			}
			case "doute_slides": { 
				AppDatas.appView.navigateTo("doute");
				break; 
			}
			case "drame2_slides": { 
				AppDatas.appView.navigateTo("drame2");
				break; 
			}
			case "enquete2_slides": { 
				AppDatas.appView.navigateTo("enquete2");
				break; 
			}
			case "enquete2_1_slides": { 
				AppDatas.appView.navigateTo("enquete3");
				break; 
			}
			case "enquete2_2_slides": { 
				AppDatas.appView.navigateTo("enquete4");
				break; 
			}
			case "rumeur2_slides": { 
				AppDatas.appView.navigateTo("rumeur2");
				break; 
			}
			case "temoignages2_slides": { 
				AppDatas.appView.navigateTo("temoignages2");
				break; 
			}
			case "condamnation2_slides": { 
				AppDatas.appView.navigateTo("condamnation2");
				break; 
			}
			case "tolerance_slides": { 
				AppDatas.appView.navigateTo("tolerance");
				break; 
			}
			
			default: { 
				console.log("Invalid text id", this.id); 
				break;              
			} 
		} 
	}


}
