import AppDatas from '../utils/AppDatas';
import AssetsLoader from '../utils/AssetsLoader';
import GraphicsUtils from '../utils/GraphicsUtils';
import ImageParticle from './effects/ImageParticle';

export default class Enquete2View {

	constructor() {

		this.frameSize = 3;
		this.frameMargin = 4;
		this.xOffest = this.frameSize + this.frameMargin;
		this.yOffest = this.frameSize + this.frameMargin;

		this.particles = [];

        this.init();
        this.initInteraction();
        
	}

	init(){

		this.cont = new PIXI.Container();
		this.cont.alpha=0;
		AppDatas.stage.addChild(this.cont);

		this.bgback_texture = AppDatas.assets_loader.loader.resources.enquete2_bg.texture
		this.bgback_sp = new PIXI.Sprite(this.bgback_texture);
		// this.bgback_sp.interactive = true;
		// this.bgback_sp.name = "back";
		this.cont.addChild(this.bgback_sp);

		this.corde_texture = AppDatas.assets_loader.loader.resources.enquete2_corde.texture
		this.corde_sp = new PIXI.Sprite(this.corde_texture);
		this.corde_sp.x = 611;
		this.corde_sp.y = 205;
		this.cont.addChild(this.corde_sp);

		this.corps_texture = AppDatas.assets_loader.loader.resources.enquete2_corps.texture
		this.corps_sp = new PIXI.Sprite(this.corps_texture);
		this.corps_sp.x = 655;
		this.corps_sp.y = -40;
		this.cont.addChild(this.corps_sp);

		this.particles_cont = new PIXI.Container();
		this.cont.addChild(this.particles_cont);

		this.bg_gr = new PIXI.Graphics();
		this.bg_gr.beginFill(0x000000, .6);
		this.bg_gr.drawRect(0, 0, AppDatas.GAME_WIDTH, AppDatas.GAME_HEIGHT);
		this.bg_gr.endFill();
		this.bg_gr.alpha=0;
		this.cont.addChild(this.bg_gr);

		this.animIn();
	}

	initInteraction(){


		this.circle_texture = AppDatas.assets_loader.loader.resources.circle.texture
		this.circle_sp = new PIXI.Sprite(this.circle_texture);

		this.circle_sp.anchor.x = this.circle_sp.anchor.y =.5;
		this.circle_sp.x=1350;
		this.circle_sp.y=950;

		this.arrow_texture = AppDatas.assets_loader.loader.resources.arrow.texture
		this.arrow_sp = new PIXI.Sprite(this.arrow_texture);
		this.arrow_sp.name = "arrow";
	
		this.arrow_sp.anchor.x = this.arrow_sp.anchor.y =.5;
		this.arrow_sp.x=900;
		this.arrow_sp.y=1200;
		this.arrow_sp.rotation = -35 * Math.PI / 180;

		this.line_cont = new PIXI.Container();
		this.cont.addChild(this.line_cont);

		this.line_cont.addChild( GraphicsUtils.drawdash(this.arrow_sp.x + 60, this.arrow_sp.y-(this.arrow_sp.height*.5 - 15), this.circle_sp.x - 60, this.circle_sp.y+(this.arrow_sp.height*.5 - 15), 2) );

		this.cont.addChild(this.circle_sp);
		this.cont.addChild(this.arrow_sp);

		this.arrow_sp.alpha=0;
		this.circle_sp.alpha=0;
		this.line_cont.alpha=0;

		this.arrow_sp.interactive = true;
		this.arrow_sp.buttonMode = true;

		this.pointerdownMethod = (e) => this.onArrowDown(e)
		this.pointerupMethod = (e) => this.onArrowUp(e)
		this.pointerMoveMethod = (e) => this.onArrowMove(e)

		this.coeff_dir = (this.circle_sp.y - this.arrow_sp.y) / (this.circle_sp.x - this.arrow_sp.x);
		
	}


	animIn(){

		this.dp1_texture = AppDatas.assets_loader.loader.resources.dpmap1.texture
        this.dp1_sp = new PIXI.Sprite(this.dp1_texture);
        AppDatas.stage.addChild(this.dp1_sp);

        this.dp1_sp.width = this.cont.width;
        this.dp1_sp.height = this.cont.height;

        this.displacementFilter = new PIXI.filters.DisplacementFilter( this.dp1_sp );
  		this.displacementFilter.scale.x = 10
  		this.displacementFilter.scale.y = 100

  		this.cont.filters = [
			this.displacementFilter
		];


		TweenMax.to(this.cont, 1, {alpha:1, ease:Power1.easeIn});
		TweenMax.to(this.displacementFilter.scale, 3, { x: 1, y: 1, ease: Power2.easeOut, onComplete: () => this.onSceneReady()})

		// this.animOut();

	}

	onSceneReady(){
		this.arrow_sp.on("pointerdown", this.pointerdownMethod);
		this.arrow_sp.on("pointerupoutside", this.pointerupMethod);
		this.arrow_sp.on("pointerup", this.pointerupMethod);

		TweenMax.to(this.bg_gr, .3, {alpha:1, delay:0, ease:Power1.easeOut});

		TweenMax.set(this.circle_sp.scale, {x:.8, y:.8});
		TweenMax.to(this.circle_sp, .3, {alpha:1, delay:.3, ease:Power1.easeOut});

		TweenMax.to(this.circle_sp.scale, 1, {x:1, y:1, delay:.6, ease:Back.easeOut.config(3)});
		
		TweenMax.to(this.arrow_sp, .3, {alpha:1, delay:1.3, ease:Power1.easeOut});
		this.arrow_sp.x=1150;
		this.arrow_sp.y=1060;
		TweenMax.to(this.arrow_sp, .5, {x:900, y:1200, delay:1.5, ease:Power2.easeOut});

		TweenMax.to(this.line_cont, .3, {alpha:1, delay:1.5, ease:Power1.easeOut});

		TweenMax.to(this.bg_gr, .3, {alpha:0, delay:2.5, ease:Power1.easeOut});
	}


	onArrowDown(e){
		this.arrow_sp.on("pointermove", this.pointerMoveMethod);
		
	}


	onArrowMove(e){

		let xmouse = e.data.global.x / AppDatas.stage.scale.x;
		

		if(xmouse > 900 && xmouse < 1350){

			this.percent = 1-((1350 - xmouse) / 450)

			this.arrow_sp.x = xmouse;
			//this.arrow_sp.y = this.arrow_sp.y + (xmouse * this.coeff_dir);
			this.arrow_sp.y = 1200 - (1200-950) * this.percent;

			// console.log(this.arrow_sp.y, this.percent);
		}

		this.updatePos();
		

	}

	updatePos(){
		TweenMax.to(this.corps_sp, 1.2, { x: 655 + (600)*this.percent, y: -40 + (-200)*this.percent, ease: Power2.easeOut})
	}

	updatePosRelease(){


		let xmouse = this.arrow_sp.x;
		this.percent = 1-((1350 - xmouse) / 450) * 1

		// console.log("updatePosRelease", xmouse, this.percent);

		this.updatePos();

	}

	onArrowUp(e){
		// console.log("up", this.arrow_sp.y)
		this.arrow_sp.off("pointermove", this.pointerMoveMethod);

		if(this.arrow_sp.x >= 1300){
			TweenMax.to(this.arrow_sp, .3, {y:this.circle_sp.y, x:this.circle_sp.x, ease:Power3.easeOut});
			TweenMax.to(this.line_cont, .3, {alpha:0, ease:Power1.easeOut});
			TweenMax.set(this.circle_sp, {alpha:0, delay:.2});
			TweenMax.to(this.arrow_sp, .3, {alpha:0, delay:.2, ease:Power1.easeOut});

			this.arrow_sp.off("pointerdown", this.pointerdownMethod);
			this.arrow_sp.off("pointerupoutside", this.pointerupMethod);
			this.arrow_sp.off("pointerup", this.pointerupMethod);

			TweenMax.to(this.corps_sp, 1.2, { x: 1900, y: -480, ease: Power2.easeOut, onComplete: () => this.animOut()})


		}else{
			TweenMax.to(this.arrow_sp, .5, {y:1200, x:900, ease:Power3.easeOut, onUpdate: () => this.updatePosRelease()});

		}
	

	}


	animOut(){

		TweenMax.to(this.displacementFilter.scale, 4, { x: 100, y: 500, delay:0, ease: Power2.easeIn, onComplete: () => this.destroy()})
		TweenMax.to(this.cont, 2.5, { alpha: 0, delay:1, ease: Power1.easeIn })

	}

	destroy(){
		this.corde_sp.destroy(true);
		this.corps_sp.destroy(true);
		this.bgback_sp.destroy(true);
		this.cont.destroy({children:true, texture:false, baseTexture:true});
		AppDatas.appView.navigateTo("enquete2_1_slides");
				

	}

}
