import AppDatas from '../utils/AppDatas';
import AssetsLoader from '../utils/AssetsLoader';
import GraphicsUtils from '../utils/GraphicsUtils';
import LocaleUtils from '../utils/LocaleUtils';
import TextView from './TextView';
import ImageParticle from './effects/ImageParticle';

export default class IntroView {

	constructor() {

		// this.showStart = false;
		this.frameSize = 3;
		this.frameMargin = 4;
		this.xOffest = this.frameSize + this.frameMargin;
		this.yOffest = this.frameSize + this.frameMargin;

		this.particles = [];

		this.windowsclicked=0;

        this.init();

	}


	init(){


		this.cont = new PIXI.Container();
		this.cont.alpha=0;
		AppDatas.stage.addChild(this.cont);

		this.bgback_texture = AppDatas.assets_loader.loader.resources.intro_bg_back.texture
		this.bgback_sp = new PIXI.Sprite(this.bgback_texture);
		this.cont.addChild(this.bgback_sp);

		let windowtextures_ar = [AppDatas.assets_loader.loader.resources.intro_windowdark.texture, AppDatas.assets_loader.loader.resources.intro_windowlight.texture]
		let windowtcoords_ar = [{x:705, y:755},{x:880, y:780},{x:1056, y:806},{x:1230, y:830},{x:1400, y:855}]
		

		this.circle_texture = AppDatas.assets_loader.loader.resources.circle.texture;
		
		for(var i=0; i<windowtcoords_ar.length; i++){
			let window_mc = new PIXI.extras.AnimatedSprite(windowtextures_ar);
			window_mc.x = windowtcoords_ar[i].x;
			window_mc.y = windowtcoords_ar[i].y;
			window_mc.name = "window"+i;
			this.cont.addChild(window_mc);
			let nb = i;
			window_mc.on("pointerup", (e) => this.onWindowClick(e,nb));


			let circle_sp = new PIXI.Sprite(this.circle_texture);

			//circle_sp.anchor.x = circle_sp.anchor.y =.5;
			circle_sp.x=windowtcoords_ar[i].x+11;
			circle_sp.y=windowtcoords_ar[i].y+16;

			circle_sp.name="windowHelper"+i;
			circle_sp.alpha = 0;

			this.cont.addChild(circle_sp);
		}


		this.bgfront_texture = AppDatas.assets_loader.loader.resources.intro_bg_front.texture
		this.bgfront_sp = new PIXI.Sprite(this.bgfront_texture);

		this.bgfront_sp.y=677;
		this.cont.addChild(this.bgfront_sp);

		this.filtre_texture = AppDatas.assets_loader.loader.resources.intro_filtre.texture

		this.filtre_sp = new PIXI.Sprite(this.filtre_texture);
		this.filtre_sp.alpha = .8;
		this.filtre_sp.blendMode = PIXI.BLEND_MODES.MULTIPLY
		this.cont.addChild(this.filtre_sp);

		this.window_t = AppDatas.assets_loader.loader.resources.intro_window.texture
		this.window_sp = new PIXI.Sprite(this.window_t);

		this.window_sp.anchor.x = this.window_sp.anchor.y =.5;
		this.window_sp.x=AppDatas.GAME_WIDTH*.5;
		this.window_sp.y=AppDatas.GAME_HEIGHT*.5;
		this.window_sp.alpha=0;
		this.cont.addChild(this.window_sp);

		this.window2_t = AppDatas.assets_loader.loader.resources.intro_window2.texture
		this.window2_sp = new PIXI.Sprite(this.window2_t);

		this.window2_sp.anchor.x = this.window2_sp.anchor.y =.5;
		this.window2_sp.x=AppDatas.GAME_WIDTH*.5;
		this.window2_sp.y=AppDatas.GAME_HEIGHT*.5;
		this.window2_sp.alpha=0;
		this.cont.addChild(this.window2_sp);

		this.curseur_t = AppDatas.assets_loader.loader.resources.curseur.texture
		this.curseur_sp = new PIXI.Sprite(this.curseur_t);

		this.curseur_sp.anchor.x = this.curseur_sp.anchor.y =.5;
		this.curseur_sp.x=AppDatas.GAME_WIDTH*.5 + 100;
		this.curseur_sp.y=AppDatas.GAME_HEIGHT*.5;
		this.curseur_sp.alpha=0;
		this.cont.addChild(this.curseur_sp);

		this.createBlackBg();

		this.title_texture = AppDatas.assets_loader.loader.resources.intro_title.texture
		this.title_sp = new PIXI.Sprite(this.title_texture);
		this.title_sp.anchor.x = .5;
		this.title_sp.anchor.y = .5;
		this.title_sp.x=AppDatas.GAME_WIDTH * .5;
		this.title_sp.y = AppDatas.GAME_HEIGHT * .5 - 350;
		// this.title_sp.y = AppDatas.GAME_HEIGHT * .5;
		AppDatas.stage.addChild(this.title_sp);
		TweenMax.set(this.title_sp, {alpha:0});

		// preambule
		this.datas_ar = LocaleUtils.getSlides("preambu");
		let preambu_style = new PIXI.TextStyle(AppDatas.introstyle);
		this.preambu_txt = new PIXI.Text(this.datas_ar[0], preambu_style);
		this.preambu_txt.anchor.x = .5;
		this.preambu_txt.anchor.y = .5;
		this.preambu_txt.x=AppDatas.GAME_WIDTH * .5;
		this.preambu_txt.y = AppDatas.GAME_HEIGHT * .5 + 150;
		this.cont.addChild(this.preambu_txt);
		this.preambu_txt.alpha = 0;

		// START BT

		this.start_bt = new PIXI.Container();
		
		this.start_bt.accessible = true;
		this.start_bt.accessibleTitle = "Commencer l'expérience";
		TweenMax.set(this.start_bt, {alpha:0});

		this.btn_texture = AppDatas.assets_loader.loader.resources.intro_btn.texture
		this.btn_sp = new PIXI.Sprite(this.btn_texture);
		this.btn_sp.anchor.x = .5;
		this.btn_sp.anchor.y = .5;
		this.start_bt.addChild(this.btn_sp);
		AppDatas.stage.addChild(this.start_bt);

		this.start_bt.x = AppDatas.GAME_WIDTH * .5
		// this.start_bt.y = this.title_sp.y + this.title_sp.height - 50
		this.start_bt.y = AppDatas.GAME_HEIGHT - 200

		let style = new PIXI.TextStyle(AppDatas.stringstyle);
		this.textBt = new PIXI.Text(LocaleUtils.getString("splash","splash_button"), style);
		this.textBt.anchor.x = .5;
		this.textBt.anchor.y = .5;
		this.start_bt.addChild(this.textBt);


		this.start_bt.on('pointerup', (e) => this.onStart(e))
		this.start_bt.on("pointerover", (e) => this.onStartOver(e));
		this.start_bt.on("pointerout", (e) => this.onStartOver(e));

		//next 
		let next_texture = AppDatas.assets_loader.loader.resources.textnext.texture
		this.next_bt = new PIXI.Sprite(next_texture);
		this.next_bt.x = AppDatas.GAME_WIDTH * .5;
		this.next_bt.y = AppDatas.GAME_HEIGHT-200;
		this.next_bt.anchor.x=this.next_bt.anchor.y=.5;
		this.next_bt.scale.x=this.next_bt.scale.y=.5;
		this.next_bt.alpha = 0;

		this.next_bt.on('pointerup', (e) => this.onNext(e))

		this.cont.addChild(this.next_bt);

		this.particles_cont = new PIXI.Container();
		this.cont.addChild(this.particles_cont);
		
		this.animIn();
	}

	onStartOver(e){
		switch(e.type) { 
		   case "pointerover": { 
				TweenMax.to(this.btn_sp.scale, .5, {x:1.1, y:1.1, ease:Power2.easeOut});
				// TweenMax.to(this.btn_sp, .5, {rotation:GraphicsUtils.degreeToRadian(2), ease:Power2.easeOut});
				TweenMax.to(this.textBt.scale, .3, {x:1.05, y:1.05, ease:Power1.easeOut});
				
				break; 
		   } 
		   case "pointerout": { 
				TweenMax.to(this.btn_sp.scale, .3, {x:1, y:1, ease:Power2.easeOut});
				// TweenMax.to(this.btn_sp, .2, {rotation:GraphicsUtils.degreeToRadian(0), ease:Power2.easeOut});
				TweenMax.to(this.textBt.scale, .2, {x:1, y:1, ease:Power1.easeOut});
		      break; 
		   }
		   default: { 
				break;              
		   } 
		} 
	}

	animIn(){

		this.dp1_texture = AppDatas.assets_loader.loader.resources.dpmap1.texture
        this.dp1_sp = new PIXI.Sprite(this.dp1_texture);
        AppDatas.stage.addChild(this.dp1_sp);

        this.dp1_sp.width = this.cont.width;
        this.dp1_sp.height = this.cont.height;

        this.displacementFilter = new PIXI.filters.DisplacementFilter( this.dp1_sp );
  		this.displacementFilter.scale.x = 10
  		this.displacementFilter.scale.y = -200

  		this.cont.filters = [
			this.displacementFilter
		];

		TweenMax.to(this.title_sp, 3, {alpha:1, ease:Power1.easeIn});

		TweenMax.to(this.cont, 2, {alpha:1, delay:1, ease:Power1.easeIn});
		TweenMax.to(this.displacementFilter.scale, 5, { x: 1, y: 1, delay:1, ease: Power2.easeOut, onComplete: () => this.onAnimOver()})

		TweenMax.to(this.preambu_txt, 1, {alpha:1, delay:4,ease:Power1.easeOut});
		TweenMax.to(this.next_bt, 1, {alpha:1, delay:4.5, ease:Power1.easeOut});
		TweenMax.to(this.blackbg, 1, {alpha:.6, delay:4.5, ease:Power1.easeOut});

		// TweenMax.to(this.start_bt, 1, {alpha:1, delay:4, ease:Power0.easeNone, onComplete: () => this.onAnimOver() });
		// TweenMax.to(this.displacementFilter.scale, 3, { x: 1, y: 1, ease: Power2.easeOut, onComplete: () => this.onSceneReady() })

		// this.textView = new TextView("intro_slides");
	}

	onAnimOver(){

		this.createParticles()

		this.next_bt.interactive = true;
		this.next_bt.buttonMode = true;
	}

	onSceneReady(){

		for(var i=0; i<5; i++){
			let window_mc = this.cont.getChildByName("window"+i);
			window_mc.interactive = true;
			window_mc.buttonMode = true;

			let windowHelper_mc = this.cont.getChildByName("windowHelper"+i);
			let nb = i;
			TweenMax.to(windowHelper_mc, .3, {alpha:1, delay:4+i*.1, repeat:1, yoyo:true, ease:Power1.easeOut, onComplete: () => this.onWindowTweenReady(nb)});
		}

	}

	onWindowTweenReady(i) {
		let windowHelper_mc = this.cont.getChildByName("windowHelper"+i);
		TweenMax.to(windowHelper_mc, .3, {alpha:1, delay:4, repeat:1, yoyo:true, ease:Power1.easeOut, onComplete: () => this.onWindowTweenReady(i)});
	}

	showTips(){

		TweenMax.to(this.blackbg, .3, {alpha:.3, delay:0, ease:Power1.easeOut});

		TweenMax.set(this.window_sp.scale, {x:.8, y:.8});
		TweenMax.to(this.window_sp, .3, {alpha:1, delay:.3, ease:Power1.easeOut});
		TweenMax.to(this.window_sp.scale, .5, {x:1, y:1, delay:.6, ease:Back.easeOut.config(3)});

		TweenMax.to(this.curseur_sp, .5, {alpha:1, delay:1, ease:Power1.easeOut});
		TweenMax.to(this.curseur_sp, 1, {x:AppDatas.GAME_WIDTH*.5+30, delay:1, ease:Power1.easeOut});

		TweenMax.to(this.window2_sp, .3, {alpha:1, delay:2, ease:Power1.easeOut});
		TweenMax.to(this.curseur_sp, .3, {alpha:0, delay:2, ease:Power1.easeOut});

		TweenMax.to(this.window_sp, .3, {alpha:0, delay:4, ease:Power1.easeOut});
		TweenMax.to(this.window2_sp, .3, {alpha:0, delay:4, ease:Power1.easeOut});

		TweenMax.to(this.blackbg, .3, {alpha:0, delay:4, ease:Power1.easeOut, onComplete: () => this.onSceneReady()});
	}

	showText(){
		this.textView = new TextView("intro_slides", false);
	}

	onNext(e){
		
		TweenMax.to(this.preambu_txt, .3, {alpha:0, ease:Power1.easeOut, onComplete: () => this.swapText()});
		TweenMax.to(this.preambu_txt, .3, {alpha:1, delay:.3, ease:Power1.easeOut});

		TweenMax.to(this.next_bt, .3, {alpha:0, ease:Power0.easeNone});
		TweenMax.to(this.start_bt, .5, {alpha:1, delay:1, ease:Power0.easeNone});

		this.next_bt.interactive = false;
		this.next_bt.buttonMode = false;

		this.start_bt.interactive = true;
		this.start_bt.buttonMode = true;
	}

	swapText(){
		this.preambu_txt.text=this.datas_ar[1];
	}

	onStart(e){
		this.start_bt.interactive = false;
		this.start_bt.buttonMode = false;
		TweenMax.to(this.preambu_txt, 1, {alpha:0, ease:Power1.easeOut});
		TweenMax.to(this.start_bt, .5, {alpha:0, ease:Power0.easeNone});
		this.animParticles();
		TweenMax.to(this.blackbg, 1, {alpha:.6,delay:4, ease:Power0.easeNone, onComplete: () => this.showText() });
		
		// this.animOut();
	}

	createBlackBg(){
		this.blackbg = new PIXI.Graphics();
		this.blackbg.beginFill(0x000000, 1);
		this.blackbg.drawRect(0, 0, AppDatas.GAME_WIDTH, AppDatas.GAME_HEIGHT);
		this.blackbg.endFill();
		this.blackbg.alpha=0;

		this.cont.addChild(this.blackbg);
	}

	createParticles(){

		this.particles_cont.x = this.title_sp.x - this.title_sp.width *.5;
		this.particles_cont.y = this.title_sp.y - this.title_sp.height *.5;

	    let count = 0;

	    const xLoopCount = Math.floor(this.title_sp.width / this.xOffest) - 1;
	    const yLoopCount = Math.floor(this.title_sp.height / this.yOffest) - 1;

	    for (let i = 0; i < xLoopCount; i+=1) {
	      for (let j = 0; j < yLoopCount; j+=1) {
	      	// console.log("id", count)
	      	// console.log(i * this.xOffest, j * this.yOffest);
	      	var particle = new ImageParticle(i * this.xOffest, j * this.yOffest, 1, this.title_texture, this.frameSize, this.title_sp.width , this.title_sp.height, count);
	        this.particles.push(particle);
	        particle.setFinalY();
	        this.particles_cont.addChild(particle.getSprite());
	        count++;
	      }
	    }

	    console.log("particles", this.particles.length);

	    
	}

	animParticles(){

		this.particles.forEach(element => element.move(2));
		TweenLite.to(this.title_sp, .5, {alpha:0, delay:1, ease:Power1.easeIn});
		
	}


	onWindowClick(e,i){

		if(e.currentTarget.currentFrame == 0){
			e.currentTarget.gotoAndStop(1);
			this.windowsclicked++;
			let windowHelper_mc = this.cont.getChildByName("windowHelper"+i);
			TweenMax.killTweensOf(windowHelper_mc);
			windowHelper_mc.alpha = 0;
		}

		if(this.windowsclicked == 5){
			this.animOut();
		}

	}

	animOut(){

		TweenMax.to(this.cont.scale, 2.5, { x: 1.3, y:1.3, ease: Power1.easeInOut })
		TweenMax.to(this.cont, 2.5, { x: -300, y:-200, ease: Power1.easeInOut })
		TweenMax.to(this.bgfront_sp, 2.5, { x:-50, y:750, ease: Power1.easeInOut })
		TweenMax.to(this.blackbg, 1, { alpha: 1, delay:2, ease: Power1.easeIn, onComplete: () => this.destroy() })

		
	}

	destroy(){
		this.title_sp.destroy(true);
		this.start_bt.destroy(true);
		this.window_sp.destroy(true);
		this.window2_sp.destroy(true);
		this.curseur_sp.destroy();
		this.cont.destroy({children:true, texture:false, baseTexture:true});
		AppDatas.appView.navigateTo("famille_slides");
				
	}


}
