import AppDatas from '../utils/AppDatas';
import AssetsLoader from '../utils/AssetsLoader';
import GraphicsUtils from '../utils/GraphicsUtils';
import ImageParticle from './effects/ImageParticle';
import 'pixi-filters';
import 'pixi-particles';

export default class EnqueteView {

	constructor() {

		this.pressed = false;


        this.init();
        //this.initInteraction();
        
	}


	init(){


		this.cont = new PIXI.Container();
		this.cont.alpha=0;
		AppDatas.stage.addChild(this.cont);


		this.bg_texture = AppDatas.assets_loader.loader.resources.enquete_bg.texture
		this.bg_sp = new PIXI.Sprite(this.bg_texture);
		this.cont.addChild(this.bg_sp);

		this.light_texture = AppDatas.assets_loader.loader.resources.enquete_light.texture
		this.light_sp = new PIXI.Sprite(this.light_texture);
		this.light_sp.anchor.x = this.light_sp.anchor.y =.5;
		this.light_sp.x = 230;
		this.light_sp.y = 720;
		this.cont.addChild(this.light_sp);

		this.particles_cont = new PIXI.Container();
		this.particles_cont.x = 150;
		this.particles_cont.y = 680;

		this.candleon_texture = AppDatas.assets_loader.loader.resources.enquete_candleon.texture
		this.candleon_sp = new PIXI.Sprite(this.candleon_texture);
		this.candleon_sp.x = 0;
		this.candleon_sp.y = 430;
		this.cont.addChild(this.candleon_sp);

		this.cont.addChild(this.particles_cont);

		this.candleoff_texture = AppDatas.assets_loader.loader.resources.enquete_candleoff.texture
		this.candleoff_sp = new PIXI.Sprite(this.candleoff_texture);
		this.candleoff_sp.x = 72;
		this.candleoff_sp.y = 475;
		this.candleoff_sp.visible = false;
		this.cont.addChild(this.candleoff_sp);

		this.bg_gr = new PIXI.Graphics();
		this.bg_gr.beginFill(0x000000, .6);
		this.bg_gr.drawRect(0, 0, AppDatas.GAME_WIDTH, AppDatas.GAME_HEIGHT);
		this.bg_gr.endFill();
		this.bg_gr.alpha=0;
		this.cont.addChild(this.bg_gr);

		this.picto_t = AppDatas.assets_loader.loader.resources.enquete_picto.texture
		this.picto_sp = new PIXI.Sprite(this.picto_t);

		this.picto_sp.anchor.x = this.picto_sp.anchor.y =.5;
		this.picto_sp.x=AppDatas.GAME_WIDTH*.5;
		this.picto_sp.y=AppDatas.GAME_HEIGHT*.5;
		this.picto_sp.alpha=0;
		this.cont.addChild(this.picto_sp);

		this.shadows_t = AppDatas.assets_loader.loader.resources.enquete_shadows.texture
		this.shadows_sp = new PIXI.Sprite(this.shadows_t);

		this.shadows_sp.anchor.x = this.shadows_sp.anchor.y =.5;
		this.shadows_sp.x=AppDatas.GAME_WIDTH*.5;
		this.shadows_sp.y=AppDatas.GAME_HEIGHT*.5 + 100;
		this.shadows_sp.alpha=0;
		this.cont.addChild(this.shadows_sp);

		this.circle_texture = AppDatas.assets_loader.loader.resources.circle.texture;
		this.circle_sp = new PIXI.Sprite(this.circle_texture);

		this.circle_sp.anchor.x = this.circle_sp.anchor.y =.5;
		this.circle_sp.x=236;
		this.circle_sp.y=720;

		this.circle_sp.name="candleHelper";
		this.circle_sp.alpha = 0;

		this.cont.addChild(this.circle_sp);

		this.animIn();
	}



	animIn(){

		this.dp1_texture = AppDatas.assets_loader.loader.resources.dpmap1.texture
        this.dp1_sp = new PIXI.Sprite(this.dp1_texture);
        AppDatas.stage.addChild(this.dp1_sp);

        this.dp1_sp.width = this.cont.width;
        this.dp1_sp.height = this.cont.height;

		this.displacementFilter = new PIXI.filters.DisplacementFilter( this.dp1_sp );
  		this.displacementFilter.scale.x = 10
  		this.displacementFilter.scale.y = 100

  		this.cont.filters = [
			this.displacementFilter,
		];


		TweenMax.to(this.cont, 1, {alpha:1, ease:Power1.easeIn});
		TweenMax.to(this.displacementFilter.scale, 3, { x: 1, y: 1, ease: Power2.easeOut, onComplete: () => this.onSceneReady()})

		this.elapsed = Date.now();
		this.emitter = new PIXI.particles.Emitter(this.particles_cont, AppDatas.assets_loader.loader.resources.part_pixel.texture, AppDatas.particlesfire);
		this.emitterflame = new PIXI.particles.Emitter(this.particles_cont, AppDatas.assets_loader.loader.resources.part_smoke.texture, AppDatas.particlesflame);
		this.updateParticles();

		this.tl = new TimelineMax({
		  repeat:-1, 
		  repeatDelay:0,
		  yoyo:true
		});

		this.tl.to(this.light_sp, .1, {alpha:.8})
  			.to(this.light_sp, .2, {alpha:1}, "+=.1")
  			.to(this.light_sp, .2, {alpha:.7}, "+=.3")
  			.to(this.light_sp, .1, {alpha:1}, "+=.5")

  		console.log(this.emitter);

	}

	initInteraction(){

		this.candleon_sp.interactive = true;
		this.candleon_sp.buttonMode = true;

		this.pointerdownMethod = (e) => this.onArrowDown(e);
		this.pointerupMethod = (e) => this.onArrowUp(e);

		this.candleon_sp.on("pointerdown", this.pointerdownMethod);
		this.candleon_sp.on("pointerup", this.pointerupMethod);

		TweenMax.to(this.circle_sp, 0.6, {alpha:1, delay:6, repeat:1, yoyo:true, ease:Power1.easeOut, onComplete: () => this.onCircleTweenReady()});
	}

	onCircleTweenReady() {
		TweenMax.to(this.circle_sp, 0.6, {alpha:1, delay:6, repeat:1, yoyo:true, ease:Power1.easeOut, onComplete: () => this.onCircleTweenReady()});
	}

	onSceneReady(){
		//this.candleon_sp.on("pointerdown", this.pointerdownMethod);
		//this.candleon_sp.on("pointerup", this.pointerupMethod);

		TweenMax.to(this.bg_gr, .3, {alpha:1, delay:0, ease:Power1.easeOut});

		TweenMax.set(this.picto_sp.scale, {x:.8, y:.8});
		TweenMax.to(this.picto_sp, .3, {alpha:1, delay:.3, ease:Power1.easeOut});
		TweenMax.to(this.picto_sp.scale, .5, {x:1, y:1, delay:.6, ease:Back.easeOut.config(3)});
		TweenMax.to(this.picto_sp, .3, {alpha:0, delay:3, ease:Power1.easeOut});

		TweenMax.to(this.bg_gr, .3, {alpha:0, delay:3, ease:Power1.easeOut, onComplete: () => this.initInteraction()});
	}

	onArrowDown(e){

		this.pressed = true;
		// this.emitterflame.minStartRotation = 300;
		this.emitterflame.maxStartRotation = 340;
		
	}

	onArrowUp(e){
		this.switchCandle();
		this.pressed = false;

		TweenMax.killTweensOf(this.circle_sp);
		this.circle_sp.alpha = 0;

		this.candleon_sp.off("pointerdown", this.pointerdownMethod);
		this.candleon_sp.off("pointerup", this.pointerupMethod);
		this.candleon_sp.interactive = false;
		this.candleon_sp.buttonMode = false;
		
	}

	switchCandle(){
		// this.light_sp.visible = false;
		// this.candleon_sp.visible = false;
		this.tl.kill();
		TweenMax.to(this.candleon_sp, .3, {alpha:0, ease:Power2.easeOut});
		TweenMax.to(this.light_sp, 1, {alpha:0, ease:Power1.easeOut});
		this.candleoff_sp.visible = true;

		this.emitter.cleanup();
		this.emitterflame.destroy();
		this.emitterflame= null;


		this.particles_cont.x = 240;
		this.particles_cont.y = 710;
		this.emitter = new PIXI.particles.Emitter(this.particles_cont, AppDatas.assets_loader.loader.resources.part_smoke.texture, AppDatas.particlessmoke);

		TweenMax.to(this.shadows_sp, 2.5, {alpha:.7, y:AppDatas.GAME_HEIGHT*.5 + 80, ease:Power1.easeIn});

		setTimeout(() => this.animOut(), 3000); 

	}


	updateParticles(){

		requestAnimationFrame(() => this.updateParticles());

		var now = Date.now();

		this.emitter.update((now - this.elapsed) * 0.001);
		if (this.emitterflame){

			this.emitterflame.update((now - this.elapsed) * 0.001);

		} 
		this.elapsed = now;

	}
	

	animOut(){

		TweenMax.to(this.displacementFilter.scale, 4, { x: 100, y: 500, delay:1, ease: Power2.easeIn, onComplete: () => this.destroy()})
		TweenMax.to(this.cont, 2.5, { alpha: 0, delay:2, ease: Power1.easeIn })
	}

	destroy(){
		this.emitter.destroy();
		// this.bg_sp.destroy(true);
		this.light_sp.destroy(true);
		this.candleon_sp.destroy(true);
		this.candleoff_sp.destroy(true);
		this.cont.destroy({children:true, texture:false, baseTexture:true});
		AppDatas.appView.navigateTo("rumeur_slides");

	}

}
