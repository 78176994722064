import AppDatas from '../utils/AppDatas';
import AssetsLoader from '../utils/AssetsLoader';
import GraphicsUtils from '../utils/GraphicsUtils';
import LocaleUtils from '../utils/LocaleUtils';
import TextView from './TextView';
import ImageParticle from './effects/ImageParticle';

export default class RewindView {

	constructor() {

        this.init();
        
	}

	init(){

		this.bgcont = new PIXI.Container();
		// this.bgcont.alpha=0;
		AppDatas.stage.addChild(this.bgcont);

		this.cont = new PIXI.Container();
		this.cont.alpha=0;
		AppDatas.stage.addChild(this.cont);

		this.rewind_texture = AppDatas.assets_loader.loader.resources.rewind.texture
		this.rewind_sp = new PIXI.Sprite(this.rewind_texture);
		this.rewind_sp.anchor.x = this.rewind_sp.anchor.y =.5;
		this.rewind_sp.x = AppDatas.GAME_WIDTH * .5
		this.rewind_sp.y = AppDatas.GAME_HEIGHT * .5
		this.cont.addChild(this.rewind_sp);

		this.circle_texture = AppDatas.assets_loader.loader.resources.circle.texture
		this.circle_sp = new PIXI.Sprite(this.circle_texture);

		this.circle_sp.anchor.x = this.circle_sp.anchor.y =.5;
		this.circle_sp.x=this.rewind_sp.x-10;
		this.circle_sp.y=this.rewind_sp.y-110;
		this.circle_sp.scale.y=this.circle_sp.scale.x = .8

		this.arrow_texture = AppDatas.assets_loader.loader.resources.arrow.texture
		this.arrow_sp = new PIXI.Sprite(this.arrow_texture);
	
		this.arrow_sp.anchor.x = this.arrow_sp.anchor.y =.5;
		this.arrow_sp.x=this.rewind_sp.x+102;
		this.arrow_sp.y=this.rewind_sp.y-10;
		this.arrow_sp.rotation = Math.PI / 2
		this.arrow_sp.scale.y=this.arrow_sp.scale.x = .8

		this.cont.addChild(this.circle_sp);
		this.cont.addChild(this.arrow_sp);

		this.arrow_sp.interactive = true;
		this.arrow_sp.buttonMode = true;

		this.pointerdownMethod = (e) => this.onArrowDown(e)
		this.pointerupMethod = (e) => this.onArrowUp(e)
		this.pointerMoveMethod = (e) => this.onArrowMove(e)

		let style = new PIXI.TextStyle(AppDatas.stringstyle);
		this.textBt = new PIXI.Text(LocaleUtils.getString("rewind","rewind_title"), style);
		this.textBt.anchor.x = .5;
		this.textBt.anchor.y = .5;
		this.textBt.x = AppDatas.GAME_WIDTH * .5
		this.textBt.y = AppDatas.GAME_HEIGHT * .5 + 250
		this.cont.addChild(this.textBt);

		this.animIn();
	}

	animIn(){
		TweenMax.to(this.cont, 2, {alpha:1, ease:Power1.easeIn});
		this.arrow_sp.on("pointerdown", this.pointerdownMethod);
		this.arrow_sp.on("pointerupoutside", this.pointerupMethod);
		this.arrow_sp.on("pointerup", this.pointerupMethod);
	}

	onArrowDown(e){
		this.arrow_sp.on("pointermove", this.pointerMoveMethod);
		
	}

	onArrowMove(e){

		let ymouse = e.data.global.y / AppDatas.stage.scale.y;
		let xmouse = e.data.global.x / AppDatas.stage.scale.x;

		let angle = Math.atan2(ymouse - this.rewind_sp.y, xmouse - this.rewind_sp.x);
		this.deegrees = (GraphicsUtils.radianToDegree(angle) +360) % 360;

		// console.log(this.deegrees);

		if( this.deegrees>0 && this.deegrees<270 ){
			this.arrow_sp.x = (this.rewind_sp.x-15) + 120 * Math.cos(angle);
		    this.arrow_sp.y = (this.rewind_sp.y+10) + 120 * Math.sin(angle);
		    this.arrow_sp.rotation = (Math.PI / 2)+angle;
		    
		}
		
		this.updatePos()
	}

	updatePos(){
		// TweenMax.to(this.map_sp, 1, { y: AppDatas.GAME_HEIGHT - this.map_sp.height + (700)*this.percent, ease: Power2.easeOut})

	}

	updatePosRelease(){

		// this.percent = (Math.round(this.arrow_y-this.arrow_sp.y)/this.dragSize);
		// this.updatePos();

	}

	onArrowUp(e){
		console.log("up", this.deegrees)
		this.arrow_sp.off("pointermove", this.pointerMoveMethod);
		this.angleback = {value:this.deegrees}

		if( (this.deegrees>240) ){
			TweenMax.to(this.angleback, .3, { value: 270, onUpdate:() => this.onAngleUpdate(), ease: Power1.easeOut })
			this.animOut();
		}else{
			// TweenMax.to(this.arrow_sp, 1, { x: (this.rewind_sp.x-15) + 120 * Math.cos(0), y: (this.rewind_sp.y+10) + 120 * Math.sin(0), ease: Power1.easeOut })
			TweenMax.to(this.angleback, 1, { value: 0, onUpdate:() => this.onAngleUpdate(), ease: Power1.easeOut })

		}

	}

	onAngleUpdate(){
		let angle = GraphicsUtils.degreeToRadian(this.angleback.value);

		this.arrow_sp.x = (this.rewind_sp.x-15) + 120 * Math.cos(angle);  
		this.arrow_sp.y = (this.rewind_sp.y+10) + 120 * Math.sin(angle); 
		this.arrow_sp.rotation = (Math.PI / 2)+angle; 
	}

	animOut(){

		TweenMax.to(this.cont, .5, {alpha:0, ease: Power2.easeIn })

		this.door_texture = AppDatas.assets_loader.loader.resources.drame_door.texture
		this.door_sp = new PIXI.Sprite(this.door_texture);
		this.door_sp.anchor.x = this.door_sp.anchor.y =.5;
		this.door_sp.x = AppDatas.GAME_WIDTH * .5
		this.door_sp.y = AppDatas.GAME_HEIGHT * .5
		this.bgcont.addChild(this.door_sp);

		this.bg_texture = AppDatas.assets_loader.loader.resources.enquete_bg.texture
		this.enquet_sp = new PIXI.Sprite(this.bg_texture);
		this.enquet_sp.anchor.x = this.enquet_sp.anchor.y =.5;
		this.enquet_sp.x = AppDatas.GAME_WIDTH * .5
		this.enquet_sp.y = AppDatas.GAME_HEIGHT * .5
		this.bgcont.addChild(this.enquet_sp);

		this.bg_texture = AppDatas.assets_loader.loader.resources.condamnation_bg.texture
		this.condamnation_sp = new PIXI.Sprite(this.bg_texture);
		this.condamnation_sp.anchor.x = this.condamnation_sp.anchor.y =.5;
		this.condamnation_sp.x = AppDatas.GAME_WIDTH * .5
		this.condamnation_sp.y = AppDatas.GAME_HEIGHT * .5
		this.bgcont.addChild(this.condamnation_sp);

		this.bg_texture = AppDatas.assets_loader.loader.resources.doute_bg.texture
		this.doute_sp = new PIXI.Sprite(this.bg_texture);
		this.doute_sp.anchor.x = this.doute_sp.anchor.y =.5;
		this.doute_sp.x = AppDatas.GAME_WIDTH * .5
		this.doute_sp.y = AppDatas.GAME_HEIGHT * .5
		this.bgcont.addChild(this.doute_sp);

		this.zoomFilter = new PIXI.filters.BlurFilter(0, 3);
		this.zoomFilter.blur = 2;
		
  		this.bgcont.filters = [
			this.zoomFilter,
		];

		TweenMax.to(this.doute_sp.scale, .5, {x:1.2, y:1.2, delay:0, ease: Power3.easeOut })
		TweenMax.to(this.condamnation_sp.scale, .5, {x:1.2, y:1.2, delay:.3, ease: Power3.easeOut })
		TweenMax.to(this.enquet_sp.scale, .5, {x:1.2, y:1.2, delay:.6, ease: Power3.easeOut })
		TweenMax.to(this.door_sp.scale, .5, {x:1.2, y:1.2, delay:1, ease: Power3.easeOut })

		TweenMax.to(this.doute_sp, .5, {alpha:0, delay:0, ease: Power3.easeIn })
		TweenMax.to(this.condamnation_sp, .5, {alpha:0, delay:.3, ease: Power3.easeIn })
		TweenMax.to(this.enquet_sp, .5, {alpha:0, delay:.6, ease: Power3.easeIn })
		TweenMax.to(this.door_sp, .5, {alpha:0, delay:1, ease: Power3.easeIn })

		TweenMax.set(this.condamnation_sp, {alpha:1, delay:1.4})
		TweenMax.set(this.condamnation_sp.scale, {x:1, y:1, delay:1.4})

		TweenMax.to(this.condamnation_sp.scale, 2, {x:1.3, y:1.3, delay:1.4, ease: Power3.easeOut })
		TweenMax.to(this.condamnation_sp, 2, {alpha:0, delay:1.4, ease: Power3.easeIn})
		TweenMax.to(this.zoomFilter, 2, {blur:8, delay:1.4, ease: Power3.easeIn, onComplete:() => this.destroy()})



		// this.doute_sp.scale.x = this.doute_sp.scale.y = this.doute_sp.alpha=1;
		// this.condamnation_sp.scale.x = this.condamnation_sp.scale.y = this.condamnation_sp.alpha=1;
		// this.enquet_sp.scale.x = this.enquet_sp.scale.y = this.enquet_sp.alpha=1;
		// this.door_sp.scale.x = this.door_sp.scale.y = this.door_sp.alpha=1;


	}

	destroy(){
		this.rewind_sp.destroy(true);
		this.cont.destroy({children:true, texture:false, baseTexture:true});
		this.bgcont.destroy({children:true, texture:false, baseTexture:true});
		AppDatas.appView.navigateTo("drame2_slides");		

	}


}
