import AppDatas from '../utils/AppDatas';
import AssetsLoader from '../utils/AssetsLoader';
import GraphicsUtils from '../utils/GraphicsUtils';
import ImageParticle from './effects/ImageParticle';
// import 'pixi-filters';
import * as filters from 'pixi-filters';

export default class Enquete4View {

	constructor() {

		this.count = 0;

        this.init();
        this.initInteraction();
        
	}


	init(){

		this.cont = new PIXI.Container();
		this.cont.alpha=0;
		AppDatas.stage.addChild(this.cont);

		this.bg_texture = AppDatas.assets_loader.loader.resources.enquete4_bg.texture
		this.bg_sp = new PIXI.Sprite(this.bg_texture);
		this.bg_sp.anchor.x = this.bg_sp.anchor.y =.5;
		this.bg_sp.x = AppDatas.GAME_WIDTH * .5
		this.bg_sp.y = AppDatas.GAME_HEIGHT * .5
		this.cont.addChild(this.bg_sp);

		this.particles_cont = new PIXI.Container();
		this.particles_cont.x = 1550;
		this.particles_cont.y = 650;
		this.cont.addChild(this.particles_cont);

		this.bulles_ar = new Array();
		this.pos_ar = new Array({x:900,y:400},{x:800,y:100},{x:600,y:700});

		for (var i = 0; i < 3; i++) {
			// console.log("rumeur_bulle"+((i%4)+1))
			this.bulle_texture = AppDatas.assets_loader.loader.resources["enquete4_bulle"+((i%3)+1)].texture
			this.bulle_sp = new PIXI.Sprite(this.bulle_texture);
			this.bulle_sp.x = this.pos_ar[i].x;
			this.bulle_sp.y = this.pos_ar[i].y;
			this.bulle_sp.alpha=0;
			this.cont.addChild(this.bulle_sp);

			this.bulles_ar.push(this.bulle_sp)
		}

		this.animIn();
	}


	animIn(){

		this.dp1_texture = AppDatas.assets_loader.loader.resources.dpmap1.texture
        this.dp1_sp = new PIXI.Sprite(this.dp1_texture);
        AppDatas.stage.addChild(this.dp1_sp);

        this.dp1_sp.width = this.cont.width;
        this.dp1_sp.height = this.cont.height;

        this.displacementFilter = new PIXI.filters.DisplacementFilter( this.dp1_sp );
  		this.displacementFilter.scale.x = 10
  		this.displacementFilter.scale.y = 100

  		this.cont.filters = [
			this.displacementFilter,
		];


		TweenMax.to(this.cont, 1, {alpha:1, ease:Power1.easeIn});
		TweenMax.to(this.displacementFilter.scale, 3, { x: 1, y: 1, ease: Power2.easeOut, onComplete: () => this.onSceneReady()})

		this.elapsed = Date.now();
		this.emitter = new PIXI.particles.Emitter(this.particles_cont, AppDatas.assets_loader.loader.resources.part_pixel.texture, AppDatas.particlescircle);
		this.updateParticles();


	}

	initInteraction(){

		this.circle_texture = AppDatas.assets_loader.loader.resources.circle.texture
		this.circle_sp = new PIXI.Sprite(this.circle_texture);

		this.circle_sp.anchor.x = this.circle_sp.anchor.y =.5;
		this.circle_sp.x=1550;
		this.circle_sp.y=650;

		this.circle_sp.interactive = true;
		this.circle_sp.buttonMode = true;

		this.cont.addChild(this.circle_sp);

		this.tl = new TimelineMax({
		  repeat:-1, 
		  repeatDelay:3
		});

		this.tl.to(this.circle_sp.scale, .4, {x:.9, y:.9})
  			.to(this.circle_sp.scale, .2, {x:1, y:1})

	}

	onSceneReady(){

		this.pointerupMethod = (e) => this.onPointerUp(e)
		this.circle_sp.on("pointerup", this.pointerupMethod);

	}

	onPointerUp(e){

		// this.bulles_ar[this.count].alpha = 1;

		TweenMax.to(this.bulles_ar[this.count], 1, {alpha:1, ease: Power2.easeOut})
		TweenMax.set(this.bulles_ar[this.count].scale, {x:1.1, y:1.1})
		TweenMax.to(this.bulles_ar[this.count].scale, .3, {x:1, y:1, ease: Power3.easeOut})

		this.count ++;

		if(this.count == 3){
			this.circle_sp.off("pointerup", this.pointerupMethod);
			this.circle_sp.visible = false;
			this.animOut();
			this.emitter.cleanup();
			this.emitter.destroy();
			this.emitter= null;
		}

	}

	updateParticles(){
		if(this.emitter){
			requestAnimationFrame(() => this.updateParticles());
			var now = Date.now();
			this.emitter.update((now - this.elapsed) * 0.001); 
			this.elapsed = now;
		}
	}

	animOut(){

		this.cont.filters = [
			this.displacementFilter,
		];


		TweenMax.to(this.displacementFilter.scale, 4, { x: 100, y: 500, delay:1, ease: Power2.easeIn, onComplete: () => this.destroy()})
		TweenMax.to(this.cont, 2.5, { alpha: 0, delay:2, ease: Power1.easeIn })
	}

	destroy(){

		this.cont.destroy({children:true, texture:false, baseTexture:true});
		AppDatas.appView.navigateTo("rumeur2_slides");

	}

}
