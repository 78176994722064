import AppDatas from '../utils/AppDatas';
import AssetsLoader from '../utils/AssetsLoader';
import GraphicsUtils from '../utils/GraphicsUtils';
import ImageParticle from './effects/ImageParticle';

export default class FamilleView {

	constructor() {

		this.frameSize = 3;
		this.frameMargin = 4;
		this.xOffest = this.frameSize + this.frameMargin;
		this.yOffest = this.frameSize + this.frameMargin;

		this.particles = [];

        this.init();
        this.initInteraction();
        
	}


	init(){


		this.cont = new PIXI.Container();
		this.cont.alpha=0;
		AppDatas.stage.addChild(this.cont);

		this.bgback_texture = AppDatas.assets_loader.loader.resources.famille_bg.texture
		this.bgback_sp = new PIXI.Sprite(this.bgback_texture);
		// this.bgback_sp.interactive = true;
		// this.bgback_sp.name = "back";
		this.cont.addChild(this.bgback_sp);

		this.ma_texture = AppDatas.assets_loader.loader.resources.famille_ma.texture
		this.ma_sp = new PIXI.Sprite(this.ma_texture);
		this.ma_sp.x = 611;
		this.ma_sp.y = 205;
		this.cont.addChild(this.ma_sp);

		this.particles_cont = new PIXI.Container();
		this.cont.addChild(this.particles_cont);

		this.bg_gr = new PIXI.Graphics();
		this.bg_gr.beginFill(0x000000, .6);
		this.bg_gr.drawRect(0, 0, AppDatas.GAME_WIDTH, AppDatas.GAME_HEIGHT);
		this.bg_gr.endFill();
		this.bg_gr.alpha=0;
		this.cont.addChild(this.bg_gr);

		this.animIn();
	}

	initInteraction(){

		console.log("texture", AppDatas.assets_loader.loader.resources.circle.texture);

		this.circle_texture = AppDatas.assets_loader.loader.resources.circle.texture
		this.circle_sp = new PIXI.Sprite(this.circle_texture);

		this.circle_sp.anchor.x = this.circle_sp.anchor.y =.5;
		this.circle_sp.x=900;
		this.circle_sp.y=550;

		this.arrow_texture = AppDatas.assets_loader.loader.resources.arrow.texture
		this.arrow_sp = new PIXI.Sprite(this.arrow_texture);
		this.arrow_sp.name = "arrow";
	
		this.arrow_sp.anchor.x = this.arrow_sp.anchor.y =.5;
		this.arrow_sp.x=900;
		this.arrow_sp.y=800;
		this.arrow_sp.rotation = - Math.PI / 2;

		this.line_cont = new PIXI.Container();
		this.cont.addChild(this.line_cont);

		this.line_cont.addChild( GraphicsUtils.drawdash(this.arrow_sp.x, this.arrow_sp.y-(this.arrow_sp.height*.5 + 10), this.circle_sp.x, this.circle_sp.y+(this.arrow_sp.height*.5 + 10), 2) );

		this.cont.addChild(this.circle_sp);
		this.cont.addChild(this.arrow_sp);

		this.arrow_sp.alpha=0;
		this.circle_sp.alpha=0;
		this.line_cont.alpha=0;

		this.arrow_sp.interactive = true;
		this.arrow_sp.buttonMode = true;

		this.pointerdownMethod = (e) => this.onArrowDown(e);
		this.pointerupMethod = (e) => this.onArrowUp(e);
		this.pointerMoveMethod = (e) => this.onArrowMove(e);
	}


	animIn(){

		this.dp1_texture = AppDatas.assets_loader.loader.resources.dpmap1.texture
        this.dp1_sp = new PIXI.Sprite(this.dp1_texture);
        AppDatas.stage.addChild(this.dp1_sp);

        this.dp1_sp.width = this.cont.width;
        this.dp1_sp.height = this.cont.height;

        this.displacementFilter = new PIXI.filters.DisplacementFilter( this.dp1_sp );
  		this.displacementFilter.scale.x = 10
  		this.displacementFilter.scale.y = 100

  		this.cont.filters = [
			this.displacementFilter
		];


		TweenMax.to(this.cont, 1, {alpha:1, ease:Power1.easeIn});
		TweenMax.to(this.displacementFilter.scale, 3, { x: 1, y: 1, ease: Power2.easeOut, onComplete: () => this.onSceneReady()});


	}

	createParticles(){

		this.particles_cont.x = this.ma_sp.x;
		this.particles_cont.y = this.ma_sp.y;

	    let count = 0;

	    const xLoopCount = Math.floor(this.ma_sp.width / this.xOffest) - 1;
	    const yLoopCount = Math.floor(this.ma_sp.height / this.yOffest) - 1;

	    for (let i = 0; i < xLoopCount; i+=1) {
	      for (let j = 0; j < yLoopCount; j+=1) {
	      	// console.log("id", count)
	      	// console.log(i * this.xOffest, j * this.yOffest);
	      	var particle = new ImageParticle(i * this.xOffest, j * this.yOffest, 1, this.ma_texture, this.frameSize, this.ma_sp.width , this.ma_sp.height, count);
	        this.particles.push(particle);
	        this.particles_cont.addChild(particle.getSprite());
	        count++;
	      }
	    }

	    console.log("particles", this.particles.length);

	    
	}

	onSceneReady(){
		this.createParticles();
		this.arrow_sp.on("pointerdown", this.pointerdownMethod);
		this.arrow_sp.on("pointerupoutside", this.pointerupMethod);
		this.arrow_sp.on("pointerup", this.pointerupMethod);


		TweenMax.to(this.bg_gr, .3, {alpha:1, delay:0, ease:Power1.easeOut});

		TweenMax.set(this.circle_sp.scale, {x:.8, y:.8});
		TweenMax.to(this.circle_sp, .3, {alpha:1, delay:.3, ease:Power1.easeOut});

		TweenMax.to(this.circle_sp.scale, 1, {x:1, y:1, delay:.6, ease:Back.easeOut.config(3)});
		
		TweenMax.to(this.arrow_sp, .3, {alpha:1, delay:1.3, ease:Power1.easeOut});
		this.arrow_sp.y=680;
		TweenMax.to(this.arrow_sp, .5, {y:800, delay:1.5, ease:Power2.easeOut});

		TweenMax.to(this.line_cont, .3, {alpha:1, delay:1.5, ease:Power1.easeOut});

		TweenMax.to(this.bg_gr, .3, {alpha:0, delay:2.5, ease:Power1.easeOut});
	}


	onArrowDown(e){
		this.arrow_sp.on("pointermove", this.pointerMoveMethod);
		
	}

	animParticles(){
		this.particles.forEach(element => element.move(2));
		TweenLite.to(this.ma_sp, .5, {alpha:0, delay:1, ease:Power1.easeIn});
		
	}

	onArrowMove(e){

		let ymouse = e.data.global.y / AppDatas.stage.scale.x;

		if(ymouse < 800 && ymouse > 550){
			this.arrow_sp.y = e.data.global.y / AppDatas.stage.scale.x;
		}
		

		let point = new PIXI.Point (this.circle_sp.x, this.circle_sp.y)
		// let point = new PIXI.Point (0, 0)
		// console.log(this.circle_sp.x, this.circle_sp.y, this.arrow_sp.x ,this.arrow_sp.y);

		// console.log(AppDatas.renderer.plugins.interaction.hitTest(point).name);

		// console.log(AppDatas.renderer.plugins.interaction.mouse);



	}

	onArrowUp(e){
		// console.log("up", this.arrow_sp.y)
		this.arrow_sp.off("pointermove", this.pointerMoveMethod);

		if(this.arrow_sp.y < 600){
			TweenMax.to(this.arrow_sp, .3, {y:this.circle_sp.y, ease:Power3.easeOut});
			TweenMax.to(this.line_cont, .3, {alpha:0, ease:Power1.easeOut});
			TweenMax.set(this.circle_sp, {alpha:0, delay:.2});
			TweenMax.to(this.arrow_sp, .3, {alpha:0, delay:.2, ease:Power1.easeOut});

			this.arrow_sp.off("pointerdown", this.pointerdownMethod);
			this.arrow_sp.off("pointerupoutside", this.pointerupMethod);
			this.arrow_sp.off("pointerup", this.pointerupMethod);

			this.animParticles();
			setTimeout(() => this.animOut(), 2500); 

		}

		if(this.arrow_sp.y >= 600){
			TweenMax.to(this.arrow_sp, .5, {y:800, ease:Power3.easeOut});
		}
	}

	animOut(){

		TweenMax.to(this.displacementFilter.scale, 4, { x: 100, y: 500, ease: Power2.easeIn, onComplete: () => this.destroy()})
		TweenMax.to(this.cont, 2.5, { alpha: 0, delay:1, ease: Power1.easeIn })
		// TweenMax.to(this.blackbg, 1, { alpha: 1, delay:2, ease: Power1.easeIn, onComplete: () => this.destroy() })

		
	}

	destroy(){
		this.bgback_sp.destroy(true);
		this.ma_sp.destroy(true);
		this.cont.destroy({children:true, texture:false, baseTexture:true});
		AppDatas.appView.navigateTo("drame_slides");
				

	}

}
