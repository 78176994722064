import AppDatas from '../utils/AppDatas';
import AssetsLoader from '../utils/AssetsLoader';

export default class LoadingView {

	constructor() {


        this.init();
        
	}


	init(){

		this.load_texture = PIXI.Texture.fromImage('assets/img/loader.png');
		this.load_sp = new PIXI.Sprite(this.load_texture);


		this.load_sp.anchor.x = .5;
		this.load_sp.anchor.y = .5;

		this.load_sp.x=AppDatas.GAME_WIDTH * .5;
		this.load_sp.y=AppDatas.GAME_HEIGHT * .5;

		AppDatas.stage.addChild(this.load_sp);

		this.show();
		
	}

	show(){
		this.load_sp.visible = true;
		TweenMax.to(this.load_sp, 1.5, {rotation:Math.PI*2, repeat:-1, ease:Power0.easeNone});
	}


	hide(){
		TweenMax.killTweensOf(this.load_sp);
		this.load_sp.visible = false;
	}

}
