import Stats from 'stats.js';
import AppDatas from '../utils/AppDatas';
import AssetsLoader from '../utils/AssetsLoader';
import LocaleUtils from '../utils/LocaleUtils';
// import device from 'current-device';

//views

import LoadingView from './LoadingView';
import TextView from './TextView';
import SplashView from './SplashView';
import IntroView from './IntroView';
import FamilleView from './FamilleView';
import DrameView from './DrameView';
import EnqueteView from './EnqueteView';
import RumeurView from './RumeurView';
import TemoignagesView from './TemoignagesView';
import CondamnationView from './CondamnationView';
import DouteView from './DouteView';
import RewindView from './RewindView';

import Drame2View from './Drame2View';
import Enquete2View from './Enquete2View';
import Enquete3View from './Enquete3View';
import Enquete4View from './Enquete4View';
import Rumeur2View from './Rumeur2View';
import Temoignages2View from './Temoignages2View';
import Condamnation2View from './Condamnation2View';
import ToleranceView from './ToleranceView';


export default class AppView {

	constructor() {

		AppDatas.appView = this;

		this.loader = null;

        this.init();
        
	}

	init(){

		let md = new MobileDetect(window.navigator.userAgent);

		if(md.tablet()){
			AppDatas.isTablet = true;
		}else{
			AppDatas.isTablet = false;
		}

		// console.log("is tablet?", AppDatas.isTablet);

		this.initPixi();
		this.loadingView = new LoadingView();
		this.loadTextDatas();
		
	}

	loadTextDatas(){

		var request = new XMLHttpRequest();
		request.open('GET', 'datas/text_fr.json', true);

		request.onload = () => {
		  if (request.status >= 200 && request.status < 400) {
		    // Success!
		    var data = JSON.parse(request.responseText);

		    AppDatas.text_datas = data;
			LocaleUtils.datas = data;
			console.log("json text loaded", AppDatas.text_datas);

			this.loadAssetsDatas();

		  } else {
		    // We reached our target server, but it returned an error

		  }
		};

		request.onerror = () => {
		  // There was a connection error of some sort
		};

		request.send();
	}

	loadAssetsDatas(){

		var request = new XMLHttpRequest();
		request.open('GET', 'datas/assets.json', true);

		request.onload = () => {
		  if (request.status >= 200 && request.status < 400) {
		    // Success!
		    var data = JSON.parse(request.responseText);

		    AppDatas.assets_datas = data;
			console.log("json assets loaded", AppDatas.assets_datas);

			AppDatas.assets_loader = new AssetsLoader();
			AppDatas.assets_loader.onDone.add(this.onLoadingDone.bind(this))
			AppDatas.assets_loader.loadAssets("common")
			

		  } else {

		  }
		};

		request.onerror = () => {

		};

		request.send();

	}


	initPixi(){

		var app = new PIXI.Application(1200, 800, {antialias:true, resolution:1, backgroundColor:0x000000, roundPixels:false});
		PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;

		// AppDatas.renderer = PIXI.autoDetectRenderer(1200, 800, {antialias:true, resolution:1, backgroundColor:0x111111});
        //this.renderer.backgroundColor = 0x1099bb;
        AppDatas.renderer = app.renderer;
        AppDatas.stage = new PIXI.Container();
        this.stage = AppDatas.stage;
        this.renderer = AppDatas.renderer;
        this.container = new PIXI.Container();
        this.stage.addChild(this.container);

        document.body.appendChild(this.renderer.view);

        window.addEventListener("resize", this.resize.bind(this));

		this.animate();
		this.resize();
	}


	onLoadingDone(pId_str){
		// this.loader.onDone.detachAll();
		console.log("end LOAD", pId_str)
		this.loadingView.hide();

		switch(pId_str) { 
		   case "common": { 
				this.introView = new IntroView();
				AppDatas.assets_loader.loadAssets("famille");

				break; 
		   }
		   case "famille": { 
				AppDatas.assets_loader.loadAssets("drame")
		      break; 
		   }
		   case "drame": { 
		   		AppDatas.assets_loader.loadAssets("enquete")
		   		break; 
		   		
		   }
		   case "enquete": {
		   		AppDatas.assets_loader.loadAssets("rumeur")
		   		break; 
		   		
		   }
		   case "rumeur": { 
		   		AppDatas.assets_loader.loadAssets("temoignages")
		   		break; 
		   		
		   }
		   case "temoignages": { 
		   		AppDatas.assets_loader.loadAssets("condamnation")
		   		break;
		   }
		   case "condamnation": { 
		   		AppDatas.assets_loader.loadAssets("doute")
		   		break;	
		   }
		   case "doute": { 
		   		AppDatas.assets_loader.loadAssets("drame2")
		   		break;
		   }
		   case "rewind": { 
		   		// AppDatas.assets_loader.loadAssets("rewind")
		   		break;
		   }
		   case "drame2": { 
		   		AppDatas.assets_loader.loadAssets("enquete2")
		   		break;
		   }
		   case "enquete2": { 
		   		AppDatas.assets_loader.loadAssets("enquete3")
		   		break;
		   }
		   case "enquete3": { 
		   		AppDatas.assets_loader.loadAssets("enquete4")
		   		break;
		   }
		   case "enquete4": { 
		   		AppDatas.assets_loader.loadAssets("rumeur2")
		   		break;
		   }
		   case "rumeur2": { 
		   		AppDatas.assets_loader.loadAssets("temoignages2")
		   		break;
		   }
		   case "temoignages2": { 
		   		AppDatas.assets_loader.loadAssets("condamnation2")
		   		break;
		   }
		   case "condamnation2": { 
				AppDatas.assets_loader.loadAssets("tolerance")
				break; 
			}
			case "tolerance": { 
				 //this.navigateTo("tolerance");
				break; 
			}

		   default: { 
				console.log("onLoadingDone Invalid id", pId_str); 
				break;
		   }
		} 
		
	}

	navigateTo(pId_str){

		console.log(">>> navigateTo <<<", pId_str);

		let slides_str = pId_str.substring(pId_str.length-6, pId_str.length);

		if(slides_str=="slides"){
			this.textView = new TextView(pId_str);
			return;
		}

		switch(pId_str) { 
			case "famille": { 
				this.familleView = new FamilleView();
				break; 
			}
			case "drame": { 
				this.drameView = new DrameView();
				break; 
			}
			case "enquete": { 
				this.enqueteView = new EnqueteView();
				break; 
			}
			case "rumeur": { 
				this.rumeurView = new RumeurView();
				break; 
			}
			case "temoignages": { 
				this.temoignagesView = new TemoignagesView();
				break; 
			}
			case "condamnation": { 
				this.condamnationView = new CondamnationView();
				break; 
			}
			case "doute": { 
				this.douteView = new DouteView();
				break; 
			}
			case "rewind": { 
				this.rewindView = new RewindView();
				break; 
			}
			case "drame2": { 
				this.drame2View = new Drame2View();
				break; 
			}
			case "enquete2": { 
				this.enquete2View = new Enquete2View();
				break; 
			}
			case "enquete3": { 
				this.enquete3View = new Enquete3View();
				break; 
			}
			case "enquete4": { 
				this.enquete4View = new Enquete4View();
				break; 
			}
			case "rumeur2": { 
				this.rumeur2View = new Rumeur2View();
				break; 
			}
			case "temoignages2": { 
				this.temoignages2View = new Temoignages2View();
				break; 
			}
			case "condamnation2": { 
				this.condamnation2View = new Condamnation2View();
				break; 
			}
			case "tolerance": { 
				this.toleranceView = new ToleranceView();
				break; 
			}
			case "startagain": { 
				this.loadAssetsDatas();
				break; 
			}
			default: { 
				console.log("navigateTo Invalid id", pId_str); 
				break;
			}
		}
	}


	resize(e){

	    let ratio = Math.min(window.innerWidth/AppDatas.GAME_WIDTH, window.innerHeight/AppDatas.GAME_HEIGHT);
		this.stage.scale.x = this.stage.scale.y = ratio;
		this.renderer.resize(Math.ceil(AppDatas.GAME_WIDTH * ratio), Math.ceil(AppDatas.GAME_HEIGHT * ratio));

		// console.log("--- resize ---")
		// console.log(AppDatas.GAME_WIDTH, AppDatas.GAME_HEIGHT)
		// console.log(ratio)
		let vignette_g = document.getElementsByClassName("vignette_g")[0]
		let canvas_x = (window.innerWidth-this.renderer.width)*.5
		let vignetteg_x = vignette_g.clientWidth*.42;

		// console.log(vignetteg_x, canvas_x)

		let vignette_d = document.getElementsByClassName("vignette_d")[0]
		let vignetteg_w = vignette_d.clientWidth*.40;

		vignette_g.style.left = (canvas_x-vignetteg_x)+"px"
		vignette_d.style.right = (canvas_x-vignetteg_w)+"px"


	}

	animate(){

		//this.stats.begin();

		this.renderer.render(this.stage);

		//this.stats.end();

       	window.requestAnimationFrame(this.animate.bind(this));
		
		
	}


}
