import AppDatas from './AppDatas';
import Signal from 'mini-signals';

export default class AssetsLoader {
  

  constructor(pId_str) {

    this.batchId_str="";
    this.currentObj;
    this.onDone = new Signal();

  }

  loadAssets(pId_str){

    this.batchId_str = pId_str;

    for (let obj of AppDatas.assets_datas) {
      // console.log(obj);

      if(obj.id == this.batchId_str){
        this.currentObj = obj
        // console.log("FOUND", this.batchId_str, this.currentObj, obj);
        break;
      }

    }

    this.startLoad();

  }

  startLoad(){

    // console.log("start LOAD", this.batchId_str);

    if(!this.loader){
      this.loader = new PIXI.loaders.Loader;
    }


    for (let obj of this.currentObj.list) {
      this.loader.add({key:obj.id, url: obj.asset})
      // console.log(obj.id,":", obj.asset);
    }

    this.loader.once('complete',this.onAssetsLoaded.bind(this));
    this.loader.on('progress', this.onProgress, this);
    this.loader.on('load', this.onLoaded, this);

    this.loader.load();

  }

  onAssetsLoaded() {
    // console.log("LOAD COMPLETE", this.batchId_str);
    this.loader.off('progress', this.onProgress, this);
    this.loader.off('load', this.onLoaded, this);
    this.onDone.dispatch(this.batchId_str);
  }

  onProgress(loader, ressource) {
    //console.log(ressource.name, 'loaded.', 'progress:', loader.progress, '%');

  }

  onLoaded(loader, ressource) {

    // this.text_cont.off("pointerup", this.pointerupMethod);

  }

  destroy() {
    this.loader.destroy();
  }
}