import AppDatas from '../utils/AppDatas';
import AssetsLoader from '../utils/AssetsLoader';
import LocaleUtils from '../utils/LocaleUtils';

export default class SplashView {

	constructor() {

		this.init();

	}

	init(){

		this.cont = new PIXI.Container();
		AppDatas.stage.addChild(this.cont);

		this.bgback_texture = AppDatas.assets_loader.loader.resources.intro_bg_back.texture
		this.bgback_sp = new PIXI.Sprite(this.bgback_texture);
		this.cont.addChild(this.bgback_sp);

		this.title_texture = AppDatas.assets_loader.loader.resources.splash_title.texture
		this.title_sp = new PIXI.Sprite(this.title_texture);
		this.title_sp.anchor.x = .5;
		this.title_sp.anchor.y = .5;
		this.title_sp.x=AppDatas.GAME_WIDTH * .5;
		this.title_sp.y = 50 / AppDatas.stage.scale.x;
		this.cont.addChild(this.title_sp);

		let text = new PIXI.Text(LocaleUtils.getString("splash","splash_text"), new PIXI.TextStyle(AppDatas.introstyle));

		text.anchor.x = .5;
		// text.anchor.y = .5;
		text.width = 1200;

		text.x=AppDatas.GAME_WIDTH * .5;
		text.y= 200 / AppDatas.stage.scale.x;

		text.accessible = true;
		text.interactive = true;
		text.accessibleTitle = LocaleUtils.getString("splash","splash_text");
		text.accessibleHint = LocaleUtils.getString("splash","splash_text");

		this.cont.addChild(text);

		this.createTextButton(LocaleUtils.getString("splash","splash_button"), 20, AppDatas.GAME_WIDTH * .5, text.y + text.height + (150/ AppDatas.stage.scale.x));

		// console.log("text position:", AppDatas.renderer.width, text.x, AppDatas.stage.scale);

		// AppDatas.assets_loader.destroy();

	}

	createTextButton(pText, pMargin, x, y){

		this.start_bt = new PIXI.Container();
		this.start_bt.interactive = true;
		this.start_bt.buttonMode = true;
		this.start_bt.accessible = true;
		this.start_bt.accessibleTitle = "Commencer l'expèrience";

		this.start_bt.on('pointerup', (e) => this.onStart(e))

		let graphics = new PIXI.Graphics();
		this.start_bt.addChild(graphics);

		let style = new PIXI.TextStyle(AppDatas.stringstyle);

		let text = new PIXI.Text(pText, style);
		// text.width = 800 ;

		text.anchor.x = .5;
		text.anchor.y = .5;

		// console.log("renderer", AppDatas.renderer)

		graphics.beginFill(0xffffff);
		graphics.drawRoundedRect(text.x-text.width *.5 - pMargin, text.y-text.height *.5 - pMargin, text.width+pMargin*2, text.height+pMargin*2, 10);
		graphics.endFill();

		this.start_bt.x=x
		this.start_bt.y=y

		this.start_bt.addChild(text);
		this.cont.addChild(this.start_bt);

	}

	onStart(e){
		this.destroy();
		// AppDatas.appView.navigateTo("intro_slides");
	}

	destroy(e){
		this.cont.destroy(true);
	}


}
