import AppDatas from '../utils/AppDatas';
import AssetsLoader from '../utils/AssetsLoader';
import GraphicsUtils from '../utils/GraphicsUtils';
import RndUtils from '../utils/RndUtils';
import ImageParticle from './effects/ImageParticle';
import LocaleUtils from '../utils/LocaleUtils';
import * as filters from 'pixi-filters';

export default class ToleranceView {

	constructor() {

		this.percent = 0;

        this.init();
        this.initInteraction();
        
	}

	init(){
		this.cont = new PIXI.Container();
		this.cont.alpha=0;
		AppDatas.stage.addChild(this.cont);

		//DEBUG
		//this.arrow_texture = AppDatas.assets_loader.loader.resources.arrow.texture
		//this.showText();
		//return;
		//End debug

		this.bg_texture = AppDatas.assets_loader.loader.resources.tolerance_bg.texture
		this.bg_sp = new PIXI.Sprite(this.bg_texture);
		this.bg_sp.anchor.x = this.bg_sp.anchor.y =.5;
		this.bg_sp.x = AppDatas.GAME_WIDTH * .5
		this.bg_sp.y = AppDatas.GAME_HEIGHT * .5
		this.cont.addChild(this.bg_sp);

		this.signature_t = AppDatas.assets_loader.loader.resources.tolerance_signature.texture
		this.signature_sp = new PIXI.Sprite(this.signature_t);
		this.signature_sp.anchor.x = this.signature_sp.anchor.y =.5;
		this.signature_sp.x = AppDatas.GAME_WIDTH * .5 - 100;
		this.signature_sp.y = AppDatas.GAME_HEIGHT * .5 - 200;
		this.cont.addChild(this.signature_sp);

		this.main_t = AppDatas.assets_loader.loader.resources.tolerance_main.texture
		this.main_sp = new PIXI.Sprite(this.main_t);
		this.main_sp.anchor.x = this.main_sp.anchor.y = 1;
		this.startmainX = this.main_sp.x = AppDatas.GAME_WIDTH - 80;
		this.startmainY = this.main_sp.y = AppDatas.GAME_HEIGHT + 450;
		this.cont.addChild(this.main_sp);

		this.bg_gr = new PIXI.Graphics();
		this.bg_gr.beginFill(0x000000, .6);
		this.bg_gr.drawRect(0, 0, AppDatas.GAME_WIDTH, AppDatas.GAME_HEIGHT);
		this.bg_gr.endFill();
		this.bg_gr.alpha=0;
		this.cont.addChild(this.bg_gr);

		this.mask_gr = new PIXI.Graphics();
		this.mask_gr.beginFill(0xff0000, .7);
		// this.mask_gr.drawRect(0, 350, 150, 350);
		this.mask_gr.drawEllipse(0, 500, 120, 350);
		this.mask_gr.endFill();
		this.mask_gr.x = 200;
		this.cont.addChild(this.mask_gr);

		this.signature_sp.mask = this.mask_gr;

		this.animIn();
	}


	animIn(){

		this.dp1_texture = AppDatas.assets_loader.loader.resources.dpmap1.texture
        this.dp1_sp = new PIXI.Sprite(this.dp1_texture);
        AppDatas.stage.addChild(this.dp1_sp);

        this.dp1_sp.width = this.cont.width;
        this.dp1_sp.height = this.cont.height;

        this.displacementFilter = new PIXI.filters.DisplacementFilter( this.dp1_sp );
  		this.displacementFilter.scale.x = 200
  		this.displacementFilter.scale.y = 10

  		this.cont.filters = [
			this.displacementFilter,
		];

		TweenMax.to(this.cont, 1, {alpha:1, ease:Power1.easeIn});
		TweenMax.to(this.displacementFilter.scale, 3, { x: 1, y: 1, ease: Power2.easeOut, onComplete: () => this.onSceneReady()})

	}

	initInteraction(){

		this.dragSize = 600;
		this.arrow_x = AppDatas.GAME_WIDTH * .5 - this.dragSize* .5;
		this.circle_x = AppDatas.GAME_WIDTH * .5 + this.dragSize* .5;

		this.circle_texture = AppDatas.assets_loader.loader.resources.circle.texture
		this.circle_sp = new PIXI.Sprite(this.circle_texture);

		this.circle_sp.anchor.x = this.circle_sp.anchor.y =.5;
		this.circle_sp.x=this.circle_x;
		this.circle_sp.y=1200;

		this.arrow_texture = AppDatas.assets_loader.loader.resources.arrow.texture
		this.arrow_sp = new PIXI.Sprite(this.arrow_texture);
		this.arrow_sp.name = "arrow";
	
		this.arrow_sp.anchor.x = this.arrow_sp.anchor.y =.5;
		this.arrow_sp.x=this.arrow_x;
		this.arrow_sp.y=1200;
		// this.arrow_sp.rotation = -90 * Math.PI / 180;

		this.line_cont = new PIXI.Container();
		this.cont.addChild(this.line_cont);

		this.line_cont.addChild( GraphicsUtils.drawdash(this.arrow_sp.x+(this.arrow_sp.width*.5 + 15) , this.arrow_sp.y, this.circle_sp.x-(this.circle_sp.width*.5 + 15), this.circle_sp.y, 2) );

		this.cont.addChild(this.circle_sp);
		this.cont.addChild(this.arrow_sp);

		this.arrow_sp.alpha=0;
		this.circle_sp.alpha=0;
		this.line_cont.alpha=0;

		this.arrow_sp.interactive = true;
		this.arrow_sp.buttonMode = true;

		this.pointerdownMethod = (e) => this.onArrowDown(e)
		this.pointerupMethod = (e) => this.onArrowUp(e)
		this.pointerMoveMethod = (e) => this.onArrowMove(e)
		
	}

	onSceneReady(){
		this.arrow_sp.on("pointerdown", this.pointerdownMethod);
		this.arrow_sp.on("pointerupoutside", this.pointerupMethod);
		this.arrow_sp.on("pointerup", this.pointerupMethod);

		TweenMax.to(this.bg_gr, .3, {alpha:1, delay:0, ease:Power1.easeOut});

		TweenMax.set(this.circle_sp.scale, {x:.8, y:.8});
		TweenMax.to(this.circle_sp, .3, {alpha:1, delay:.3, ease:Power1.easeOut});

		TweenMax.to(this.circle_sp.scale, 1, {x:1, y:1, delay:.6, ease:Back.easeOut.config(3)});
		
		TweenMax.to(this.arrow_sp, .3, {alpha:1, delay:1.3, ease:Power1.easeOut});
		this.arrow_sp.x=this.arrow_x+300;
		TweenMax.to(this.arrow_sp, .5, {x:this.arrow_x, delay:1.5, ease:Power2.easeOut});

		TweenMax.to(this.line_cont, .3, {alpha:1, delay:1.5, ease:Power1.easeOut});

		TweenMax.to(this.bg_gr, .3, {alpha:0, delay:2.5, ease:Power1.easeOut});

	}

	onArrowDown(e){
		this.arrow_sp.on("pointermove", this.pointerMoveMethod);
		
	}

	onArrowMove(e){

		let xmouse = e.data.global.x / AppDatas.stage.scale.x;
		
		if(xmouse > this.arrow_x  && xmouse < this.circle_x ){
			this.arrow_sp.x = e.data.global.x / AppDatas.stage.scale.x;
		}

		this.percent = (Math.round(this.arrow_sp.x-this.arrow_x)/this.dragSize);
		// console.log(this.percent)

		this.updatePos()
	}

	updatePos(){
		// let ypos = this.startmainY + Math.cos(this.percent)*500
		let ypos = this.startmainY + Math.sin(0.1*(this.percent*100)) * 100;
		 // console.log(1100*this.percent, this.mask_gr.width)
		TweenMax.to(this.main_sp, .1, { x: this.startmainX + 1100*this.percent, y:ypos, ease: Power2.easeOut})
		TweenMax.to(this.mask_gr, .3, { width: 240 + 2300*this.percent, ease: Power2.easeOut})
	}

	updatePosRelease(){

		this.percent = (Math.round(this.arrow_sp.x-this.arrow_x)/this.dragSize);
		this.updatePos();

	}

	onArrowUp(e){
		// console.log("up", this.arrow_sp.y)
		this.arrow_sp.off("pointermove", this.pointerMoveMethod);

		if(this.arrow_sp.x >= this.circle_x-100){
			TweenMax.to(this.arrow_sp, .3, {y:this.circle_sp.y, x:this.circle_sp.x, ease:Power3.easeOut});
			TweenMax.to(this.line_cont, .3, {alpha:0, ease:Power1.easeOut});
			TweenMax.set(this.circle_sp, {alpha:0, delay:.2});
			TweenMax.to(this.arrow_sp, .3, {alpha:0, delay:.2, ease:Power1.easeOut});

			this.arrow_sp.off("pointerdown", this.pointerdownMethod);
			this.arrow_sp.off("pointerupoutside", this.pointerupMethod);
			this.arrow_sp.off("pointerup", this.pointerupMethod);

			TweenMax.to(this.mask_gr, .3, { width: 240 + 2350, ease: Power2.easeOut})
			this.animOut();
			
		}else{
			TweenMax.to(this.arrow_sp, .5, {y:1200, x:this.arrow_x, ease:Power3.easeOut, onUpdate: () => this.updatePosRelease()});

		}
	
	}

	showText(){

		let text = "";

		for(var i=1; i<=3; i++){
			text += LocaleUtils.getString("tolerance_citations", i) + "\n\n\n";
			
		}

		this.deftext = new PIXI.Text(text, new PIXI.TextStyle(AppDatas.quotestyle));
		this.cont.addChild(this.deftext);
		this.deftext.x=AppDatas.GAME_WIDTH * .5 - this.deftext.width *.5;
		this.deftext.y= AppDatas.GAME_HEIGHT * .5 - this.deftext.height *.5;

		TweenMax.to(this.cont, 1, { alpha: 1, ease: Power1.easeOut })


		this.minisignature_sp = new PIXI.Sprite(this.signature_t);
		this.minisignature_sp.anchor.x = this.minisignature_sp.anchor.y =.5;
		this.minisignature_sp.scale.x = this.minisignature_sp.scale.y =.3;
		this.minisignature_sp.x = AppDatas.GAME_WIDTH * .5;
		this.minisignature_sp.y = this.deftext.y + this.deftext.height;
		this.cont.addChild(this.minisignature_sp);

		this.ajustFilter = new filters.AdjustmentFilter({gamma:1.8, saturation:0, contrast:1.5})

		this.minisignature_sp.filters = [
			this.ajustFilter
		];


		this.arrow_sp = new PIXI.Sprite(this.arrow_texture);
	
		this.arrow_sp.anchor.x = this.arrow_sp.anchor.y =.5;
		this.arrow_sp.x=AppDatas.GAME_WIDTH * .5;
		this.arrow_sp.y=this.minisignature_sp.y + 200;
		this.arrow_sp.rotation = 90 * Math.PI / 180;

		this.arrow_sp.interactive = true;
		this.arrow_sp.buttonMode = true;

		this.cont.addChild(this.arrow_sp);

		this.pointerupMethod = (e) => this.onShowCredits(e)
		this.arrow_sp.on("pointerup", this.pointerupMethod);

	}


	animOut(){

		this.cont.filters = [
			this.displacementFilter,
		];

		TweenMax.to(this.displacementFilter.scale, 4, { x: 100, y: 500, delay:0, ease: Power2.easeIn, onComplete: () => this.destroy()})
		TweenMax.to(this.cont, 2.5, { alpha: 0, delay:1, ease: Power1.easeIn })
	}

	onShowCredits(){
		this.arrow_sp.off("pointerup", this.pointerupMethod);
		TweenMax.to(this.deftext, 2, {y:0, alpha: 0, delay:0, ease: Power2.easeIn })
		TweenMax.to(this.minisignature_sp, 2, {y:500, alpha: 0, delay:.3, ease: Power2.easeIn })
		TweenMax.to(this.arrow_sp, 2, {y:800, alpha: 0, delay:.5, ease: Power2.easeIn })

		let text = "";

		for(var i=1; i<=3; i++){
			text += LocaleUtils.getString("credits", i) + "\n\n";
			
		}

		this.creditstext = new MultiStyleText(text, new PIXI.TextStyle(AppDatas.creditstyle));
		this.creditContainer = new PIXI.Container();
		this.cont.addChild(this.creditContainer);
		this.creditstext.x=AppDatas.GAME_WIDTH * .5 - this.creditstext.width *.5;
		this.creditstext.y= AppDatas.GAME_HEIGHT;

		this.creditContainer.addChild(this.creditstext);

		let logoOffset = 400;
		this.logo1 = AppDatas.assets_loader.loader.resources.tolerance_logo1.texture
		this.logo1_sp = new PIXI.Sprite(this.logo1);
		this.logo1_sp.anchor.x = this.logo1_sp.anchor.y =.5;
		this.logo1_sp.x = AppDatas.GAME_WIDTH * .5 + 55;
		this.logo1_sp.y = this.creditstext.y+logoOffset;
		this.creditContainer.addChild(this.logo1_sp);

		this.logo2 = AppDatas.assets_loader.loader.resources.tolerance_logo2.texture
		this.logo2_sp = new PIXI.Sprite(this.logo2);
		this.logo2_sp.anchor.x = this.logo2_sp.anchor.y =.5;
		this.logo2_sp.x = AppDatas.GAME_WIDTH * .5 + 523;
		this.logo2_sp.y = this.creditstext.y+ logoOffset;
		this.creditContainer.addChild(this.logo2_sp);

		this.logo3 = AppDatas.assets_loader.loader.resources.tolerance_logo3.texture
		this.logo3_sp = new PIXI.Sprite(this.logo3);
		this.logo3_sp.anchor.x = this.logo3_sp.anchor.y =.5;
		this.logo3_sp.x = AppDatas.GAME_WIDTH * .5 - 469;
		this.logo3_sp.y = this.creditstext.y+ logoOffset;
		this.creditContainer.addChild(this.logo3_sp);

		this.logo4 = AppDatas.assets_loader.loader.resources.tolerance_logo4.texture
		this.logo4_sp = new PIXI.Sprite(this.logo4);
		this.logo4_sp.anchor.x = this.logo4_sp.anchor.y =.5;
		this.logo4_sp.x = AppDatas.GAME_WIDTH * .5;
		this.logo4_sp.y = this.creditstext.y + logoOffset+385;
		this.creditContainer.addChild(this.logo4_sp);

		TweenMax.to(this.creditContainer, 38, {y:-this.creditstext.y-this.creditstext.height, delay:2, ease: Power0.easeNone, onComplete: () => this.startAgain()})
	}

	destroy(){

		this.mask_gr.destroy(true);
		this.bg_sp.destroy(true);
		this.signature_sp.destroy();
		this.main_sp.destroy(true);
		// this.cont.destroy({children:true, texture:false, baseTexture:true});
		// AppDatas.appView.navigateTo("condamnation_slides");

		this.cont.filters = null;
		this.showText();

	}

	startAgain(){
		this.cont.destroy({children:true, texture:true, baseTexture:true});
		AppDatas.appView.navigateTo("startagain");
	}

}
