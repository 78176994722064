// import AppDatas from './AppDatas';

let _datas;



export default class LocaleUtils {

  static get datas() { return _datas; }
  static set datas(value) { _datas=value; }

  static getString(section, id) {

    let currentObj;
    let currentString;

    for (let obj of LocaleUtils.datas) {
      // console.log(section, id, obj);

      if(obj.id == section){
        currentObj = obj
        // console.log("FOUND", section, currentObj);
        return currentString = currentObj.strings[id];
        break;
      }

    }

  }

  static getSlides(section) {

    let currentObj;

    for (let obj of LocaleUtils.datas) {

      if(obj.id == section){
        currentObj = obj
        return currentObj.slides
        break;
      }

    }

  }

}