let _colors = {
	red:0xf25346,
	white:0xd8d0d1,
	brown:0x59332e,
	pink:0xF5986E,
	pink_str:'#F5986E',
	brownDark:0x23190f,
	blue:0x68c3c0,
	blue_str:'#68c3c0',
	purewhite:0xffffff,
	purewhite_str:'#ffffff',
	lightgrey:0xc2c2c2,
	lightgrey_str:'#c2c2c2',
	lightblue:0xcfdee7
	
};


let _GAME_WIDTH = 1920;
let _GAME_HEIGHT = 1440;

let _assets_datas;
let _text_datas;
let _assets_loader;
let _stage;
let _renderer;
let _appView;
let _isTablet;

let _stringstyle = {

			fontFamily : 'Libre Baskerville', 
			fontSize: 28, 
			fill : 0xffffff, 
			align : 'center'

		};

let _slidestyle = {
			"default": {
				fontFamily : 'Libre Baskerville', 
				fontSize: 38, 
				fill : 0xffffff, 
				align : 'center',
				wordWrap: true,
				wordWrapWidth: 1400,
				lineHeight : 50
			},
			"link": {
				fontFamily : 'Libre Baskerville', 
				fontSize: 38, 
				// fontStyle: "italic",
				//fill: "#ec6255"
				fill: "#ff3010"
			}
		};

let _introstyle = {

			fontFamily : 'Libre Baskerville', 
			fontSize: 38, 
			fill : 0xffffff, 
			align : 'center',
			wordWrap: true,
			wordWrapWidth: 1400,
			lineHeight : 75

		};

let _defstyle = {

			fontFamily : 'Libre Baskerville', 
			fontSize: 28,	//24
			fontStyle:"italic",
			fill : 0x000000, 
			align : 'center',
			wordWrap: true,
			wordWrapWidth: 1200,
			lineHeight : 60	//50	

		};

let _drameStyle = {

	fontFamily : 'Libre Baskerville', 
	fontSize: 26,
	fill : 0x000000, 
	align : 'center',
	wordWrap: true,
	wordWrapWidth: 1000,
	lineHeight : 50

};

let _quotestyle = {

		fontFamily : 'Libre Baskerville', 
		fontSize: 30,
		fontStyle:"italic",
		fill : 0xFFFFFF, 
		align : 'center',
		wordWrap: true,
		wordWrapWidth: 1000,
		lineHeight : 50

	};

let _creditstyle = {
			"default": {
				fontFamily : 'Libre Baskerville', 
				fontSize: 30, 
				fill : 0xffffff, 
				align : 'center',
				wordWrap: true,
				wordWrapWidth: 1400,
				lineHeight : 30
			},
			"title": {
				fontFamily : 'Libre Baskerville', 
				fontSize: 34, 
				fill: "#ebcca0",
				lineHeight : 30
			}
		};

let _particlescircle = {
	"alpha": {
		"start": 1,
		"end": 0.1
	},
	"scale": {
		"start": 0.08,
		"end": 0.04,
		"minimumScaleMultiplier": 2
	},
	"color": {
		"start": "#ffffff",
		"end": "#ffffff"
	},
	"speed": {
		"start": 15,
		"end": 10,
		"minimumSpeedMultiplier": 1
	},
	"acceleration": {
		"x": 0,
		"y": 0
	},
	"maxSpeed": 0,
	"startRotation": {
		"min": 0,
		"max": 360
	},
	"noRotation": true,
	"rotationSpeed": {
		"min": 0,
		"max": 0
	},
	"lifetime": {
		"min": 1,
		"max": 3
	},
	"blendMode": "normal",
	"frequency": 0.05,
	"emitterLifetime": -1,
	"maxParticles": 30,
	"pos": {
		"x": 0,
		"y": 0
	},
	"addAtBack": false,
	"spawnType": "ring",
	"spawnCircle": {
		"x": 0,
		"y": 0,
		"r": 60,
		"minR": 40
	}
}


let _particlesfire = {
	"alpha": {
		"start": 1,
		"end": 0.02
	},
	"scale": {
		"start": 0.07,
		"end": 0.01,
		"minimumScaleMultiplier": 2
	},
	"color": {
		"start": "#ffe100",
		"end": "#ffc400"
	},
	"speed": {
		"start": 10,
		"end": 5,
		"minimumSpeedMultiplier": 1
	},
	"acceleration": {
		"x": 0,
		"y": -5
	},
	"maxSpeed": 0,
	"startRotation": {
		"min": 0,
		"max": 360
	},
	"noRotation": true,
	"rotationSpeed": {
		"min": 0,
		"max": 0
	},
	"lifetime": {
		"min": 3,
		"max": 5
	},
	"blendMode": "normal",
	"frequency": 0.05,
	"emitterLifetime": -1,
	"maxParticles": 40,
	"pos": {
		"x": 0,
		"y": 0
	},
	"addAtBack": false,
	"spawnType": "rect",
	"spawnRect": {
		"x": 0,
		"y": 0,
		"w": 160,
		"h": 100
	}
}

let _particlessmoke = {
	"alpha": {
		"start": 0.05,
		"end": 0
	},
	"scale": {
		"start": 0.2,
		"end": 2,
		"minimumScaleMultiplier": 5
	},
	"color": {
		"start": "#cccccc",
		"end": "#c3c6c7"
	},
	"speed": {
		"start": 40,
		"end": 30,
		"minimumSpeedMultiplier": 0.2
	},
	"acceleration": {
		"x": 1,
		"y": 2
	},
	"maxSpeed": 0,
	"startRotation": {
		"min": 250,
		"max": 280
	},
	"noRotation": false,
	"rotationSpeed": {
		"min": 1,
		"max": 2
	},
	"lifetime": {
		"min": 5,
		"max": 8
	},
	"blendMode": "normal",
	"frequency": 0.1,
	"emitterLifetime": -1,
	"maxParticles": 50,
	"pos": {
		"x": 0,
		"y": 0
	},
	"addAtBack": false,
	"spawnType": "point"
}

let _particlesflame = {
	"alpha": {
		"start": 0.5,
		"end": 0
	},
	"scale": {
		"start": 1,
		"end": 3.5,
		"minimumScaleMultiplier": 1
	},
	"color": {
		"start": "#fcf158",
		"end": "#f57003"
	},
	"speed": {
		"start": 3,
		"end": 5,
		"minimumSpeedMultiplier": 1
	},
	"acceleration": {
		"x": 0,
		"y": 0
	},
	"maxSpeed": 0,
	"startRotation": {
		"min": 265,
		"max": 275
	},
	"noRotation": false,
	"rotationSpeed": {
		"min": -5,
		"max": 5
	},
	"lifetime": {
		"min": 2,
		"max": 4
	},
	"blendMode": "screen",
	"frequency": 0.1,
	"emitterLifetime": -1,
	"maxParticles": 50,
	"pos": {
		"x": 90,
		"y": 60
	},
	"addAtBack": false,
	"spawnType": "point"
}


export default class AppDatas {

	static get colors() { return _colors; }
	static get GAME_WIDTH() { return _GAME_WIDTH; }
	static get GAME_HEIGHT() { return _GAME_HEIGHT; }
	static get assets_datas() { return _assets_datas; }
	static get text_datas() { return _text_datas; }
	static get assets_loader() { return _assets_loader; }
	static get stage() { return _stage; }
	static get renderer() { return _renderer; }
	static get stringstyle() { return _stringstyle; }
	static get slidestyle() { return _slidestyle; }
	static get introstyle() { return _introstyle; }
	static get defstyle() { return _defstyle; }
	static get drameStyle() { return _drameStyle; }
	static get quotestyle() { return _quotestyle; }
	static get creditstyle() { return _creditstyle; }
	static get appView() { return _appView; }
	static get particlesfire() { return _particlesfire; }
	static get particlessmoke() { return _particlessmoke; }
	static get particlesflame() { return _particlesflame; }
	static get particlescircle() { return _particlescircle; }
	static get isTablet() { return _isTablet; }

	static set assets_datas(value) { _assets_datas=value; }
	static set text_datas(value) { _text_datas=value; }
	static set assets_loader(value) { _assets_loader=value; }
	static set stage(value) { _stage=value; }
	static set renderer(value) { _renderer=value; }
	static set stringstyle(value) { _stringstyle=value; }
	static set slidestyle(value) { _slidestyle=value; }
	static set appView(value) { _appView=value; }
	static set isTablet(value) { _isTablet=value; }

}