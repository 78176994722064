import RndUtils from '../../utils/RndUtils';

export default class ImageParticle {
  

  constructor(posX, posY, scale, baseTexture, frameSize, rendererWidth, rendererHeight, id) {

  	this.speedX = 0;
  	this.speedY = 0;
    this.maxSpeed = RndUtils.randomIntFromInterval(20, 50);
  	this.speedUpdate = RndUtils.randomIntFromInterval(5, 25);

  	this.rendererWidth = rendererWidth;
  	this.rendererHeight = rendererHeight;
    this.id = id;

    this.sprite = new PIXI.Sprite(new PIXI.Texture(baseTexture));
    this.sprite.scale.set(scale, scale);

    this.sprite.texture.frame = new PIXI.Rectangle(
      posX / scale,
      posY / scale,
      frameSize / scale,
      frameSize / scale,
    );
    // this.sprite.x = Math.random() * rendererWidth;
    // this.sprite.y = Math.random() * rendererHeight;

    var angle = Math.random()*Math.PI*2;

    // this.finalPosX = posX + Math.cos(angle)*20;
    // this.finalPosY = posY + Math.sin(angle)*20;

    this.finalPosX = RndUtils.randomIntFromInterval((rendererWidth*.5)-20, (rendererWidth*.5)+20);
    this.finalPosY = RndUtils.randomIntFromInterval(-300, -50);

    this.bezierPosX = RndUtils.randomIntFromInterval((rendererWidth*.5)+50, (rendererWidth*.5)+200);
    this.bezierPosY = RndUtils.randomIntFromInterval(-200, -100);

    this.sprite.x = posX;
    this.sprite.y = posY;

    // pixiAPP.stage.addChild(this.sprite);
  }

  setFinalY() {
    this.finalPosY = RndUtils.randomIntFromInterval(-400, -200);
  }

  getSprite() {
  	return this.sprite;
  }

  move(pDelay_num) {
    let delayRnd = RndUtils.randomFloatFromInterval(1, 10) * .000005
    // TweenLite.to(this.sprite, RndUtils.randomFloatFromInterval(1, 2), {x:this.finalPosX, y:this.finalPosY, alpha:0, delay:1+(this.id*delayRnd), ease:Power3.easeIn});
    TweenLite.to(this.sprite, RndUtils.randomFloatFromInterval(pDelay_num, pDelay_num+1.5), {bezier:{curviness:4, values:[{x:this.bezierPosX, y:this.bezierPosY}, {x:this.finalPosX, y:this.finalPosY}]}, alpha:0, delay:(this.id*delayRnd), ease:Power3.easeIn});
    // TweenLite.to(this.sprite, RndUtils.randomFloatFromInterval(1, 2), {bezier:{type:"soft", values:[{x:this.bezierPosX, y:this.bezierPosY}, {x:this.finalPosX, y:this.finalPosY}]}, alpha:0, delay:2+(this.id*delayRnd), ease:Power3.easeIn});

  }

  update() {
    this.speedX = (this.finalPosX - this.sprite.x) / this.rendererWidth * this.speedUpdate;
    this.speedY = (this.finalPosY - this.sprite.y) / this.rendererHeight * this.speedUpdate;

    const distance = Math.sqrt(Math.pow(this.finalPosX  - this.sprite.x, 2) + Math.pow(this.finalPosY - this.sprite.y, 2));
    // if (distance < 50) {
    //   const accX = (this.finalPosX  - this.sprite.x);
    //   this.speedX -= accX;

    //   const accY = (this.finalPosY - this.sprite.y);
    //   this.speedY -= accY;
    // }

    this.sprite.x += this.speedX;
    this.sprite.y += this.speedY;
    // this.sprite.alpha = distance;
    // console.log(distance);
  }

  destroy() {
    // pixiAPP.stage.removeChild(this.sprite);
    this.sprite.destroy();
  }
}