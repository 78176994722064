global.TweenMax = require('gsap');
global.PIXI = require('pixi.js');
global.MultiStyleText = require('./../vendors/pixi-multistyle-text.js')
global.WebFont = require('webfontloader');
global.MobileDetect = require('mobile-detect');

import App from './App';

function ready(fn) {
	if (document.readyState !== 'loading') {
		fn();
	} else {
		document.addEventListener('DOMContentLoaded', fn);
	}
}

ready(() => {

	WebFont.load(
	{
		// this event is triggered when the fonts have been rendered, see https://github.com/typekit/webfontloader
		active : function()
		{

			setTimeout(function()
			{
				onFontsLoaded();
			}, 500); 
		},
				
		google :
		{
			families: [ "Libre Baskerville:400,400i"]
		}
	});

});


function onFontsLoaded() {
	// console.log("onFontsLoaded");
	const app = new App();
	window.app = app;
}