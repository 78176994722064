import AppDatas from '../utils/AppDatas';
import AssetsLoader from '../utils/AssetsLoader';
import GraphicsUtils from '../utils/GraphicsUtils';
import RndUtils from '../utils/RndUtils';
import ImageParticle from './effects/ImageParticle';
import 'pixi-filters';

export default class DouteView {

	constructor() {

		this.percent = 0;

        this.init();
        this.initInteraction();
        
	}

	init(){

		this.cont = new PIXI.Container();
		this.cont.alpha=0;
		AppDatas.stage.addChild(this.cont);

		this.bg_texture = AppDatas.assets_loader.loader.resources.doute_bg.texture
		this.bg_sp = new PIXI.Sprite(this.bg_texture);
		this.bg_sp.anchor.x = this.bg_sp.anchor.y =.5;
		this.bg_sp.x = AppDatas.GAME_WIDTH * .5
		this.bg_sp.y = AppDatas.GAME_HEIGHT * .5
		this.cont.addChild(this.bg_sp);

		this.map_t = AppDatas.assets_loader.loader.resources.doute_map.texture
		this.map_sp = new PIXI.Sprite(this.map_t);
		this.map_sp.anchor.x =.5;
		this.map_sp.x = AppDatas.GAME_WIDTH * .5
		this.map_sp.y = AppDatas.GAME_HEIGHT - this.map_sp.height;
		this.cont.addChild(this.map_sp);

		this.bg_gr = new PIXI.Graphics();
		this.bg_gr.beginFill(0x000000, .6);
		this.bg_gr.drawRect(0, 0, AppDatas.GAME_WIDTH, AppDatas.GAME_HEIGHT);
		this.bg_gr.endFill();
		this.bg_gr.alpha=0;
		this.cont.addChild(this.bg_gr);

		this.animIn();
	}


	animIn(){

		this.dp1_texture = AppDatas.assets_loader.loader.resources.dpmap1.texture
        this.dp1_sp = new PIXI.Sprite(this.dp1_texture);
        AppDatas.stage.addChild(this.dp1_sp);

        this.dp1_sp.width = this.cont.width;
        this.dp1_sp.height = this.cont.height;

        this.displacementFilter = new PIXI.filters.DisplacementFilter( this.dp1_sp );
  		this.displacementFilter.scale.x = 1
  		this.displacementFilter.scale.y = 100

  		this.cont.filters = [
			this.displacementFilter,
		];


		TweenMax.to(this.cont, 1, {alpha:1, ease:Power1.easeIn});
		TweenMax.to(this.displacementFilter.scale, 3, { x: 1, y: 1, ease: Power2.easeOut, onComplete: () => this.onSceneReady()})


	}

	initInteraction(){

		this.dragSize = 500;
		this.arrow_y = AppDatas.GAME_HEIGHT * .5 - this.dragSize* .5 + 150;
		this.circle_y = AppDatas.GAME_HEIGHT * .5 + this.dragSize* .5 + 150;

		this.circle_texture = AppDatas.assets_loader.loader.resources.circle.texture
		this.circle_sp = new PIXI.Sprite(this.circle_texture);

		this.circle_sp.anchor.x = this.circle_sp.anchor.y =.5;
		this.circle_sp.x=AppDatas.GAME_WIDTH * .5;
		this.circle_sp.y=this.circle_y;

		this.arrow_texture = AppDatas.assets_loader.loader.resources.arrow.texture
		this.arrow_sp = new PIXI.Sprite(this.arrow_texture);
		this.arrow_sp.name = "arrow";
	
		this.arrow_sp.anchor.x = this.arrow_sp.anchor.y =.5;
		this.arrow_sp.x=AppDatas.GAME_WIDTH * .5;
		this.arrow_sp.y=this.arrow_y;
		this.arrow_sp.rotation = Math.PI / 2

		this.line_cont = new PIXI.Container();
		this.cont.addChild(this.line_cont);

		this.line_cont.addChild( GraphicsUtils.drawdash(this.arrow_sp.x, this.arrow_sp.y +(this.arrow_sp.width*.5 + 15), this.circle_sp.x, this.circle_sp.y-(this.arrow_sp.width*.5 + 15), 2) );

		this.cont.addChild(this.circle_sp);
		this.cont.addChild(this.arrow_sp);

		this.arrow_sp.alpha=0;
		this.circle_sp.alpha=0;
		this.line_cont.alpha=0;

		this.arrow_sp.interactive = true;
		this.arrow_sp.buttonMode = true;

		this.pointerdownMethod = (e) => this.onArrowDown(e)
		this.pointerupMethod = (e) => this.onArrowUp(e)
		this.pointerMoveMethod = (e) => this.onArrowMove(e)
		
	}

	onSceneReady(){
		this.arrow_sp.on("pointerdown", this.pointerdownMethod);
		this.arrow_sp.on("pointerupoutside", this.pointerupMethod);
		this.arrow_sp.on("pointerup", this.pointerupMethod);

		TweenMax.to(this.bg_gr, .3, {alpha:1, delay:0, ease:Power1.easeOut});

		TweenMax.set(this.circle_sp.scale, {x:.8, y:.8});
		TweenMax.to(this.circle_sp, .3, {alpha:1, delay:.3, ease:Power1.easeOut});

		TweenMax.to(this.circle_sp.scale, 1, {x:1, y:1, delay:.6, ease:Back.easeOut.config(3)});
		
		TweenMax.to(this.arrow_sp, .3, {alpha:1, delay:1.3, ease:Power1.easeOut});
		this.arrow_sp.y=this.arrow_y+200;
		TweenMax.to(this.arrow_sp, .5, {y:this.arrow_y, delay:1.5, ease:Power2.easeOut});

		TweenMax.to(this.line_cont, .3, {alpha:1, delay:1.5, ease:Power1.easeOut});

		TweenMax.to(this.bg_gr, .3, {alpha:0, delay:2.5, ease:Power1.easeOut});

	}

	onArrowDown(e){
		this.arrow_sp.on("pointermove", this.pointerMoveMethod);
		
	}


	onArrowMove(e){

		let ymouse = e.data.global.y / AppDatas.stage.scale.y;
		
		if(ymouse > this.arrow_y  && ymouse < this.circle_y ){
			this.arrow_sp.y = e.data.global.y / AppDatas.stage.scale.y;
		}

		this.percent = (Math.round(this.arrow_sp.y-this.arrow_y)/this.dragSize);
		console.log(this.percent)

		this.updatePos()
	}

	updatePos(){

		TweenMax.to(this.map_sp, 1, { y: AppDatas.GAME_HEIGHT - this.map_sp.height + (700)*this.percent, ease: Power2.easeOut})

	}

	updatePosRelease(){

		this.percent = (Math.round(this.arrow_y-this.arrow_sp.y)/this.dragSize);
		this.updatePos();

	}

	onArrowUp(e){
		// console.log("up", this.arrow_sp.y)
		this.arrow_sp.off("pointermove", this.pointerMoveMethod);

		if(this.arrow_sp.y >= this.circle_y-100){
			TweenMax.to(this.arrow_sp, .3, {y:this.circle_sp.y, x:this.circle_sp.x, ease:Power3.easeOut});
			TweenMax.to(this.line_cont, .3, {alpha:0, ease:Power1.easeOut});
			TweenMax.set(this.circle_sp, {alpha:0, delay:.2});
			TweenMax.to(this.arrow_sp, .3, {alpha:0, delay:.2, ease:Power1.easeOut});

			this.arrow_sp.off("pointerdown", this.pointerdownMethod);
			this.arrow_sp.off("pointerupoutside", this.pointerupMethod);
			this.arrow_sp.off("pointerup", this.pointerupMethod);

			this.animOut();


		}else{
			TweenMax.to(this.arrow_sp, .5, {y:this.arrow_y, ease:Power3.easeOut, onUpdate: () => this.updatePosRelease()});

		}
	

	}


	animOut(){

		TweenMax.to(this.map_sp, 1.5, { y: AppDatas.GAME_HEIGHT - this.map_sp.height + 900, ease: Power1.easeOut})

		this.cont.filters = [
			this.displacementFilter,
		];


		TweenMax.to(this.displacementFilter.scale, 4, { x: 100, y: 500, delay:1, ease: Power2.easeIn, onComplete: () => this.destroy()})
		TweenMax.to(this.cont, 2.5, { alpha: 0, delay:2, ease: Power1.easeIn })
	}

	destroy(){

		this.bg_sp.destroy();
		this.map_sp.destroy(true);
		this.cont.destroy({children:true, texture:false, baseTexture:true});
		AppDatas.appView.navigateTo("rewind");

	}

}
