import AppDatas from '../utils/AppDatas';
import AssetsLoader from '../utils/AssetsLoader';
import GraphicsUtils from '../utils/GraphicsUtils';
import ImageParticle from './effects/ImageParticle';
import LocaleUtils from '../utils/LocaleUtils';

export default class Drame2View {

	constructor() {

		this.count = 0;

        this.init();
        this.initInteraction();
        
	}

	init(){

		this.cont = new PIXI.Container();
		this.cont.alpha=0;
		AppDatas.stage.addChild(this.cont);

		this.bgback_texture = AppDatas.assets_loader.loader.resources.drame2_bg.texture
		this.bgback_sp = new PIXI.Sprite(this.bgback_texture);
		// this.bgback_sp.interactive = true;
		// this.bgback_sp.name = "back";
		this.cont.addChild(this.bgback_sp);

		this.ma_texture = AppDatas.assets_loader.loader.resources.drame2_ma.texture
		this.ma_sp = new PIXI.Sprite(this.ma_texture);
		this.ma_sp.x = 611;
		this.ma_sp.y = 205;
		this.cont.addChild(this.ma_sp);

		this.particles_cont = new PIXI.Container();
		this.particles_cont.x = 1050;
		this.particles_cont.y = 540;
		this.cont.addChild(this.particles_cont);

		this.particles2_cont = new PIXI.Container();
		this.particles2_cont.x = 350;
		this.particles2_cont.y = 650;
		this.cont.addChild(this.particles2_cont);

		this.particles3_cont = new PIXI.Container();
		this.particles3_cont.x = 1700;
		this.particles3_cont.y = 300;
		this.cont.addChild(this.particles3_cont);

		this.animIn();
	}

	initInteraction(){

		this.circle_texture = AppDatas.assets_loader.loader.resources.circle.texture
		this.circle_sp = new PIXI.Sprite(this.circle_texture);

		this.circle_sp.anchor.x = this.circle_sp.anchor.y =.5;
		this.circle_sp.x=1050;
		this.circle_sp.y=540;

		this.circle_sp.interactive = true;
		this.circle_sp.buttonMode = true;
		this.circle_sp.name="louis";

		this.cont.addChild(this.circle_sp);

		this.circle2_sp = new PIXI.Sprite(this.circle_texture);

		this.circle2_sp.anchor.x = this.circle2_sp.anchor.y =.5;
		this.circle2_sp.x=350;
		this.circle2_sp.y=650;

		this.circle2_sp.interactive = true;
		this.circle2_sp.buttonMode = true;
		this.circle2_sp.name="pere";

		this.cont.addChild(this.circle2_sp);

		this.circle3_sp = new PIXI.Sprite(this.circle_texture);

		this.circle3_sp.anchor.x = this.circle3_sp.anchor.y =.5;
		this.circle3_sp.x=1700;
		this.circle3_sp.y=300;

		this.circle3_sp.interactive = true;
		this.circle3_sp.buttonMode = true;
		this.circle3_sp.name="fenetre";

		this.cont.addChild(this.circle3_sp);

		this.pointerupMethod = (e) => this.onPointerUp(e)

		this.bg_gr = new PIXI.Graphics();
		this.bg_gr.beginFill(0x000000, .75);
		this.bg_gr.drawRect(0, 0, AppDatas.GAME_WIDTH, AppDatas.GAME_HEIGHT);
		this.bg_gr.endFill();
		this.bg_gr.alpha=0;
		this.cont.addChild(this.bg_gr);
		this.bg_gr.on("pointerup", (e) => this.onCloseDef(e));

		this.white_gr = new PIXI.Graphics();
		this.white_gr.beginFill(0xffffff, .95);
		this.white_gr.drawRect(0, 0, AppDatas.GAME_WIDTH, AppDatas.GAME_HEIGHT);
		this.white_gr.endFill();
		this.white_gr.alpha=0;
		this.cont.addChild(this.white_gr);

		this.tl = new TimelineMax({
		  repeat:-1, 
		  repeatDelay:3,
		  delay:.2
		});

		this.tl.to(this.circle_sp.scale, .4, {x:.9, y:.9})
  			.to(this.circle_sp.scale, .2, {x:1, y:1})

  		this.tl2 = new TimelineMax({
		  repeat:-1, 
		  repeatDelay:3
		});

		this.tl2.to(this.circle2_sp.scale, .4, {x:.9, y:.9})
  			.to(this.circle2_sp.scale, .2, {x:1, y:1})

  		this.tl3 = new TimelineMax({
		  repeat:-1, 
		  repeatDelay:3,
		  delay:.4
		});

		this.tl3.to(this.circle3_sp.scale, .4, {x:.9, y:.9})
  			.to(this.circle3_sp.scale, .2, {x:1, y:1})
		
	}

	animIn(){

		this.dp1_texture = AppDatas.assets_loader.loader.resources.dpmap1.texture
        this.dp1_sp = new PIXI.Sprite(this.dp1_texture);
        AppDatas.stage.addChild(this.dp1_sp);

        this.dp1_sp.width = this.cont.width;
        this.dp1_sp.height = this.cont.height;

        this.displacementFilter = new PIXI.filters.DisplacementFilter( this.dp1_sp );
  		this.displacementFilter.scale.x = 10
  		this.displacementFilter.scale.y = 100

  		this.cont.filters = [
			this.displacementFilter
		];

		TweenMax.to(this.cont, 1, {alpha:1, ease:Power1.easeIn});
		TweenMax.to(this.displacementFilter.scale, 3, { x: 1, y: 1, ease: Power2.easeOut, onComplete: () => this.onSceneReady()})

		this.elapsed = Date.now();
		this.emitter = new PIXI.particles.Emitter(this.particles_cont, AppDatas.assets_loader.loader.resources.part_pixel.texture, AppDatas.particlescircle);
		this.emitter2 = new PIXI.particles.Emitter(this.particles2_cont, AppDatas.assets_loader.loader.resources.part_pixel.texture, AppDatas.particlescircle);
		this.emitter3 = new PIXI.particles.Emitter(this.particles3_cont, AppDatas.assets_loader.loader.resources.part_pixel.texture, AppDatas.particlescircle);
		this.updateParticles();

	}

	onSceneReady(){

		this.circle_sp.on("pointerup", this.pointerupMethod);
		this.circle2_sp.on("pointerup", this.pointerupMethod);
		this.circle3_sp.on("pointerup", this.pointerupMethod);
	}

	onPointerUp(e){
		// console.log(e.currentTarget.name);
		let def = LocaleUtils.getString("drame2",e.currentTarget.name);
		e.currentTarget.off("pointerup", this.pointerupMethod);
		TweenMax.to(e.currentTarget, .5, {alpha:0, ease:Power1.easeOut});
		e.currentTarget.interactive = false;
		e.currentTarget.buttonMode = false;

		switch(e.currentTarget.name) { 
			case "louis": { 
				this.particles_cont.alpha=0;
				break; 
			}
			case "pere": { 
				this.particles2_cont.alpha=0;
				break; 
			}
			case "fenetre": { 
				this.particles3_cont.alpha=0;
				break; 
			}
		} 

		this.showDef(def);
	}

	showDef(pText){
		this.count ++;

		var style = new PIXI.TextStyle(AppDatas.drameStyle);
		if(AppDatas.isTablet){
			style.fontSize = 40;
			style.lineHeight = 70
		}

		this.deftext = new PIXI.Text(pText, style);
		this.cont.addChild(this.deftext);
		this.deftext.x=AppDatas.GAME_WIDTH * .5 - this.deftext.width *.5;
		this.deftext.y= AppDatas.GAME_HEIGHT * .5 - this.deftext.height *.5;

		this.white_gr.x = this.deftext.x - 30;
		this.white_gr.y = this.deftext.y - 30;
		this.white_gr.width = this.deftext.width + 60;
		this.white_gr.height = this.deftext.height + 50;

		TweenMax.to(this.white_gr, .5, { alpha: 1, ease: Power1.easeOut })
		TweenMax.to(this.bg_gr, .5, { alpha: 1, ease: Power1.easeOut })

		this.bg_gr.interactive = true;
		this.bg_gr.buttonMode = true;
	}

	onCloseDef(e){
		this.cont.removeChild(this.deftext);
		TweenMax.to(this.white_gr, .5, { alpha: 0, ease: Power1.easeOut })
		TweenMax.to(this.bg_gr, .5, { alpha: 0, ease: Power1.easeOut })

		this.bg_gr.interactive = false;
		this.bg_gr.buttonMode = false;

		if(this.count == 3){
			this.animOut();
		}

	}

	updateParticles(){
		if(this.emitter){
			requestAnimationFrame(() => this.updateParticles());
			var now = Date.now();
			this.emitter.update((now - this.elapsed) * 0.001); 
			this.emitter2.update((now - this.elapsed) * 0.001); 
			this.emitter3.update((now - this.elapsed) * 0.001); 
			this.elapsed = now;
		}
	}


	animOut(){

		this.emitter.cleanup();
		this.emitter.destroy();
		this.emitter= null;

		this.emitter2.cleanup();
		this.emitter2.destroy();
		this.emitter2= null;

		this.emitter3.cleanup();
		this.emitter3.destroy();
		this.emitter3= null;

		this.tl.clear();
		this.tl.kill();

		this.tl2.clear();
		this.tl2.kill();

		this.tl3.clear();
		this.tl3.kill();

		TweenMax.to(this.displacementFilter.scale, 4, { x: 100, y: 500, delay:0, ease: Power2.easeIn, onComplete: () => this.destroy()})
		TweenMax.to(this.cont, 2.5, { alpha: 0, delay:1, ease: Power1.easeIn })
		// TweenMax.to(this.blackbg, 1, { alpha: 1, delay:2, ease: Power1.easeIn, onComplete: () => this.destroy() })

	}

	destroy(){
		this.bgback_sp.destroy(true);
		this.ma_sp.destroy(true);
		this.cont.destroy({children:true, texture:false, baseTexture:true});
		AppDatas.appView.navigateTo("enquete2_slides");
				

	}

}
