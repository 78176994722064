export default class RndUtils {


	static randomIntFromInterval(min,max)
	{
    	return Math.floor( Math.random()*  ( max - min + 1 ) + min );
	}

	static randomFloatFromInterval(min,max)
	{
    	return Math.random()*  ( max - min + 1 ) + min;
	}

	static getRandomSign()
	{
    	return (Math.random() < 0.5) ? -1 : 1;
	}


}