import AppDatas from '../utils/AppDatas';
import AssetsLoader from '../utils/AssetsLoader';
import GraphicsUtils from '../utils/GraphicsUtils';
import ImageParticle from './effects/ImageParticle';
// import 'pixi-filters';
import * as filters from 'pixi-filters';

export default class DrameView {

	constructor() {

		this.percent = 0;

        this.init();
        this.initInteraction();
        
	}


	init(){


		this.cont = new PIXI.Container();
		this.cont.alpha=0;
		AppDatas.stage.addChild(this.cont);

		this.street_texture = AppDatas.assets_loader.loader.resources.drame_street.texture
		this.street_sp = new PIXI.Sprite(this.street_texture);
		this.street_sp.anchor.x = this.street_sp.anchor.y =.5;
		this.street_sp.x = AppDatas.GAME_WIDTH * .5
		this.street_sp.y = AppDatas.GAME_HEIGHT * .5
		this.cont.addChild(this.street_sp);


		let perso4_texture = AppDatas.assets_loader.loader.resources.drame_perso4.texture
		this.perso4_sp = new PIXI.Sprite(perso4_texture);
		this.cont.addChild(this.perso4_sp);
		this.perso4_sp.x = 1227;
		this.perso4_sp.y = 327;

		let perso5_texture = AppDatas.assets_loader.loader.resources.drame_perso5.texture
		this.perso5_sp = new PIXI.Sprite(perso5_texture);
		this.cont.addChild(this.perso5_sp);
		this.perso5_sp.x = 1517;
		this.perso5_sp.y = 334;

		let perso3_texture = AppDatas.assets_loader.loader.resources.drame_perso3.texture
		this.perso3_sp = new PIXI.Sprite(perso3_texture);
		this.cont.addChild(this.perso3_sp);
		this.perso3_sp.x = 1325;
		this.perso3_sp.y = 332;

		let perso2_texture = AppDatas.assets_loader.loader.resources.drame_perso2.texture
		this.perso2_sp = new PIXI.Sprite(perso2_texture);
		this.cont.addChild(this.perso2_sp);
		this.perso2_sp.x = 1365;
		this.perso2_sp.y = 482;

		let perso1_texture = AppDatas.assets_loader.loader.resources.drame_perso1.texture
		this.perso1_sp = new PIXI.Sprite(perso1_texture);
		this.cont.addChild(this.perso1_sp);
		this.perso1_sp.x = 806;
		this.perso1_sp.y = 558;

		this.door_texture = AppDatas.assets_loader.loader.resources.drame_door.texture
		this.door_sp = new PIXI.Sprite(this.door_texture);
		this.door_sp.alpha=0;
		this.door_sp.anchor.x = this.door_sp.anchor.y =.5;
		this.door_sp.x = AppDatas.GAME_WIDTH * .5
		this.door_sp.y = AppDatas.GAME_HEIGHT * .5
		this.cont.addChild(this.door_sp);


		this.bg_gr = new PIXI.Graphics();
		this.bg_gr.beginFill(0x000000, .6);
		this.bg_gr.drawRect(0, 0, AppDatas.GAME_WIDTH, AppDatas.GAME_HEIGHT);
		this.bg_gr.endFill();
		this.bg_gr.alpha=0;
		this.cont.addChild(this.bg_gr);

		this.animIn();
	}



	animIn(){

		this.dp1_texture = AppDatas.assets_loader.loader.resources.dpmap1.texture
        this.dp1_sp = new PIXI.Sprite(this.dp1_texture);
        AppDatas.stage.addChild(this.dp1_sp);

        this.dp1_sp.width = this.cont.width;
        this.dp1_sp.height = this.cont.height;

        this.displacementFilter = new PIXI.filters.DisplacementFilter( this.dp1_sp );
  		this.displacementFilter.scale.x = 10
  		this.displacementFilter.scale.y = 100

  		this.cont.filters = [
			this.displacementFilter,
		];


		TweenMax.to(this.cont, 1, {alpha:1, ease:Power1.easeIn});
		TweenMax.to(this.displacementFilter.scale, 3, { x: 1, y: 1, ease: Power2.easeOut, onComplete: () => this.onSceneReady()})


	}

	initInteraction(){


		this.circle_texture = AppDatas.assets_loader.loader.resources.circle.texture
		this.circle_sp = new PIXI.Sprite(this.circle_texture);

		this.circle_sp.anchor.x = this.circle_sp.anchor.y =.5;
		this.circle_sp.x=1350;
		this.circle_sp.y=950;

		this.arrow_texture = AppDatas.assets_loader.loader.resources.arrow.texture
		this.arrow_sp = new PIXI.Sprite(this.arrow_texture);
		this.arrow_sp.name = "arrow";
	
		this.arrow_sp.anchor.x = this.arrow_sp.anchor.y =.5;
		this.arrow_sp.x=900;
		this.arrow_sp.y=1200;
		this.arrow_sp.rotation = -35 * Math.PI / 180;

		this.line_cont = new PIXI.Container();
		this.cont.addChild(this.line_cont);

		this.line_cont.addChild( GraphicsUtils.drawdash(this.arrow_sp.x + 60, this.arrow_sp.y-(this.arrow_sp.height*.5 - 15), this.circle_sp.x - 60, this.circle_sp.y+(this.arrow_sp.height*.5 - 15), 2) );

		this.cont.addChild(this.circle_sp);
		this.cont.addChild(this.arrow_sp);

		this.arrow_sp.alpha=0;
		this.circle_sp.alpha=0;
		this.line_cont.alpha=0;

		this.arrow_sp.interactive = true;
		this.arrow_sp.buttonMode = true;

		this.pointerdownMethod = (e) => this.onArrowDown(e)
		this.pointerupMethod = (e) => this.onArrowUp(e)
		this.pointerMoveMethod = (e) => this.onArrowMove(e)

		this.coeff_dir = (this.circle_sp.y - this.arrow_sp.y) / (this.circle_sp.x - this.arrow_sp.x);
		
	}

	onSceneReady(){
		this.arrow_sp.on("pointerdown", this.pointerdownMethod);
		this.arrow_sp.on("pointerupoutside", this.pointerupMethod);
		this.arrow_sp.on("pointerup", this.pointerupMethod);

		TweenMax.to(this.bg_gr, .3, {alpha:1, delay:0, ease:Power1.easeOut});

		TweenMax.set(this.circle_sp.scale, {x:.8, y:.8});
		TweenMax.to(this.circle_sp, .3, {alpha:1, delay:.3, ease:Power1.easeOut});

		TweenMax.to(this.circle_sp.scale, 1, {x:1, y:1, delay:.6, ease:Back.easeOut.config(3)});
		
		TweenMax.to(this.arrow_sp, .3, {alpha:1, delay:1.3, ease:Power1.easeOut});
		this.arrow_sp.x=1150;
		this.arrow_sp.y=1060;
		TweenMax.to(this.arrow_sp, .5, {x:900, y:1200, delay:1.5, ease:Power2.easeOut});

		TweenMax.to(this.line_cont, .3, {alpha:1, delay:1.5, ease:Power1.easeOut});

		TweenMax.to(this.bg_gr, .3, {alpha:0, delay:2.5, ease:Power1.easeOut});

		this.cont.filters = null;

		if(AppDatas.isTablet){
			this.zoomFilter = new PIXI.filters.BlurFilter(0, 3);
			this.zoomFilter.blur = 0;
		}else{
			this.zoomFilter = new filters.ZoomBlurFilter(0, [AppDatas.renderer.width*.5 + 200, AppDatas.renderer.height*.5 - 200], 20);
		}
		
  		this.cont.filters = [
			this.zoomFilter,
		];
	}

	onArrowDown(e){
		this.arrow_sp.on("pointermove", this.pointerMoveMethod);
		
	}


	onArrowMove(e){

		let xmouse = e.data.global.x / AppDatas.stage.scale.x;
		

		if(xmouse > 900 && xmouse < 1350){

			this.percent = 1-((1350 - xmouse) / 450)

			this.arrow_sp.x = xmouse;
			//this.arrow_sp.y = this.arrow_sp.y + (xmouse * this.coeff_dir);
			this.arrow_sp.y = 1200 - (1200-950) * this.percent;

			// console.log(this.arrow_sp.y, this.percent);
		}

		this.updatePos();
		

	}

	updatePos(){
		TweenMax.to(this.perso1_sp, 1.2, { x: 806 - (806-760)*this.percent, y: 558 - (558-530)*this.percent, ease: Power2.easeOut})
		TweenMax.to(this.perso2_sp, 1.2, { x: 1365 + (1440 - 1365)*this.percent, y: 482 - (482 - 460)*this.percent, ease: Power2.easeOut})
		TweenMax.to(this.perso3_sp, 1.2, { x: 1325 + (1325 - 1290)*this.percent, ease: Power2.easeOut})
		TweenMax.to(this.perso4_sp, 1.2, { x: 1227 - (1227 - 1180)*this.percent, ease: Power2.easeOut})
		TweenMax.to(this.perso5_sp, 1.2, { x: 1517 + (1517 - 1500)*this.percent, ease: Power2.easeOut})

		TweenMax.to(this.street_sp.scale, 2, { x: 1 + (.05*this.percent), y: 1 + (.05*this.percent), ease: Power2.easeOut})
		TweenMax.to(this.street_sp, 2, { x: AppDatas.GAME_WIDTH * .5 - (45*this.percent), y:AppDatas.GAME_HEIGHT * .5 + (30*this.percent), ease: Power2.easeOut})
	}

	updatePosRelease(){


		let xmouse = this.arrow_sp.x;
		this.percent = 1-((1350 - xmouse) / 450) * 1

		// console.log("updatePosRelease", xmouse, this.percent);

		this.updatePos();

	}

	onArrowUp(e){
		// console.log("up", this.arrow_sp.y)
		this.arrow_sp.off("pointermove", this.pointerMoveMethod);

		if(this.arrow_sp.x >= 1300){
			TweenMax.to(this.arrow_sp, .3, {y:this.circle_sp.y, x:this.circle_sp.x, ease:Power3.easeOut});
			TweenMax.to(this.line_cont, .3, {alpha:0, ease:Power1.easeOut});
			TweenMax.set(this.circle_sp, {alpha:0, delay:.2});
			TweenMax.to(this.arrow_sp, .3, {alpha:0, delay:.2, ease:Power1.easeOut});

			this.arrow_sp.off("pointerdown", this.pointerdownMethod);
			this.arrow_sp.off("pointerupoutside", this.pointerupMethod);
			this.arrow_sp.off("pointerup", this.pointerupMethod);

			if(AppDatas.isTablet){
				TweenMax.to(this.zoomFilter, .8, { blur: 15, ease: Power3.easeIn, onComplete: () => this.changeScene()})
			}else{
				TweenMax.to(this.zoomFilter, .8, { strength: .5, ease: Power3.easeIn, onComplete: () => this.changeScene()})
			}


		}else{
			TweenMax.to(this.arrow_sp, .5, {y:1200, x:900, ease:Power3.easeOut, onUpdate: () => this.updatePosRelease()});

		}
	
	}

	changeScene(){

		this.door_sp.alpha=1;
		this.street_sp.alpha=0;

		if(AppDatas.isTablet){
			TweenMax.to(this.zoomFilter, .8, { blur: 0, ease: Power3.easeOut, onComplete: () => this.animOut()})
		}else{
			TweenMax.to(this.zoomFilter, .8, { strength: 0, ease: Power3.easeOut, onComplete: () => this.animOut()})
		}
	}


	animOut(){

		this.perso1_sp.visible = false;
		this.perso2_sp.visible = false;
		this.perso3_sp.visible = false;
		this.perso4_sp.visible = false;
		this.perso5_sp.visible = false;

		this.cont.filters = [
			this.displacementFilter,
		];


		TweenMax.to(this.displacementFilter.scale, 4, { x: 100, y: 500, delay:1, ease: Power2.easeIn, onComplete: () => this.destroy()})
		TweenMax.to(this.cont, 2.5, { alpha: 0, delay:2, ease: Power1.easeIn })
	}

	destroy(){
		this.street_sp.destroy(true);
		this.door_sp.destroy();
		this.perso1_sp.destroy(true);
		this.perso2_sp.destroy(true);
		this.perso3_sp.destroy(true);
		this.perso4_sp.destroy(true);
		this.perso5_sp.destroy(true);
		this.cont.destroy({children:true, texture:false, baseTexture:true});
		AppDatas.appView.navigateTo("enquete_slides");

	}

}
