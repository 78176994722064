import AppDatas from '../utils/AppDatas';
import AssetsLoader from '../utils/AssetsLoader';
import GraphicsUtils from '../utils/GraphicsUtils';
import ImageParticle from './effects/ImageParticle';
// import 'pixi-filters';
import * as filters from 'pixi-filters';

export default class Enquete3View {

	constructor() {

		this.percent = 0;

        this.init();
        this.initInteraction();
        
	}


	init(){


		this.cont = new PIXI.Container();
		this.cont.alpha=0;
		AppDatas.stage.addChild(this.cont);

		this.bg_texture = AppDatas.assets_loader.loader.resources.enquete3_bg.texture
		this.bg_sp = new PIXI.Sprite(this.bg_texture);
		this.bg_sp.anchor.x = this.bg_sp.anchor.y =.5;
		this.bg_sp.x = AppDatas.GAME_WIDTH * .5
		this.bg_sp.y = AppDatas.GAME_HEIGHT * .5
		this.cont.addChild(this.bg_sp);

		this.particles_cont = new PIXI.Container();
		this.particles_cont.x = 1000;
		this.particles_cont.y = 500;
		this.cont.addChild(this.particles_cont);

		this.bg2_t = AppDatas.assets_loader.loader.resources.enquete3_bg2.texture
		this.bg2_sp = new PIXI.Sprite(this.bg2_t);
		this.bg2_sp.anchor.x = this.bg2_sp.anchor.y =.5;
		this.bg2_sp.x = AppDatas.GAME_WIDTH * .5
		this.bg2_sp.y = AppDatas.GAME_HEIGHT * .5
		this.bg2_sp.alpha = 0;
		this.cont.addChild(this.bg2_sp);

		this.corps_t = AppDatas.assets_loader.loader.resources.enquete3_corps.texture
		this.corps_sp = new PIXI.Sprite(this.corps_t);
		this.corps_sp.anchor.x = this.corps_sp.anchor.y =.5;
		this.corps_sp.x = AppDatas.GAME_WIDTH * .5 - 370
		this.corps_sp.y = AppDatas.GAME_HEIGHT * .5 + 150
		this.corps_sp.alpha = 0;
		this.cont.addChild(this.corps_sp);

		this.tete_t = AppDatas.assets_loader.loader.resources.enquete3_tete.texture
		this.tete_sp = new PIXI.Sprite(this.tete_t);
		this.tete_sp.anchor.x = this.tete_sp.anchor.y =.5;
		this.tete_sp.x = AppDatas.GAME_WIDTH * .5 - 290
		this.tete_sp.y = AppDatas.GAME_HEIGHT * .5 - 170
		this.tete_sp.alpha = 0;
		this.cont.addChild(this.tete_sp);


		this.bg_gr = new PIXI.Graphics();
		this.bg_gr.beginFill(0x000000, .6);
		this.bg_gr.drawRect(0, 0, AppDatas.GAME_WIDTH, AppDatas.GAME_HEIGHT);
		this.bg_gr.endFill();
		this.bg_gr.alpha=0;
		this.cont.addChild(this.bg_gr);

		this.animIn();
	}


	animIn(){

		this.dp1_texture = AppDatas.assets_loader.loader.resources.dpmap1.texture
        this.dp1_sp = new PIXI.Sprite(this.dp1_texture);
        AppDatas.stage.addChild(this.dp1_sp);

        this.dp1_sp.width = this.cont.width;
        this.dp1_sp.height = this.cont.height;

        this.displacementFilter = new PIXI.filters.DisplacementFilter( this.dp1_sp );
  		this.displacementFilter.scale.x = 10
  		this.displacementFilter.scale.y = 100

  		this.cont.filters = [
			this.displacementFilter,
		];

		TweenMax.to(this.cont, 1, {alpha:1, ease:Power1.easeIn});
		TweenMax.to(this.displacementFilter.scale, 3, { x: 1, y: 1, ease: Power2.easeOut, onComplete: () => this.onSceneReady()})

		this.elapsed = Date.now();
		this.emitter = new PIXI.particles.Emitter(this.particles_cont, AppDatas.assets_loader.loader.resources.part_pixel.texture, AppDatas.particlescircle);
		this.updateParticles();

	}

	initInteraction(){


		this.circle_texture = AppDatas.assets_loader.loader.resources.circle.texture
		this.circle_sp = new PIXI.Sprite(this.circle_texture);

		this.circle_sp.anchor.x = this.circle_sp.anchor.y =.5;
		this.circle_sp.x=1000;
		this.circle_sp.y=500;

		this.circle_sp.interactive = true;
		this.circle_sp.buttonMode = true;

		this.cont.addChild(this.circle_sp);

		this.tl = new TimelineMax({
		  repeat:-1, 
		  repeatDelay:3
		});

		this.tl.to(this.circle_sp.scale, .4, {x:.9, y:.9})
  			.to(this.circle_sp.scale, .2, {x:1, y:1})
		
	}

	onSceneReady(){

		this.pointerupMethod = (e) => this.onPointerUp(e)
		this.circle_sp.on("pointerup", this.pointerupMethod);

		if(AppDatas.isTablet){
			this.zoomFilter = new PIXI.filters.BlurFilter(0, 3);
			this.zoomFilter.blur = 0;
		}else{
			this.zoomFilter = new filters.ZoomBlurFilter(0, [AppDatas.renderer.width*.5, AppDatas.renderer.height*.5], 20);
		}

  		this.cont.filters = [
			this.zoomFilter
		];
	}

	onPointerUp(e){
		this.circle_sp.off("pointerup", this.pointerupMethod);
		this.circle_sp.visible = false;

		if(AppDatas.isTablet){
			TweenMax.to(this.zoomFilter, .8, { blur: 15, ease: Power3.easeIn, onComplete: () => this.changeScene()})
		}else{
			TweenMax.to(this.zoomFilter, .8, { strength: .5, ease: Power3.easeIn, onComplete: () => this.changeScene()})
		}
	}


	changeScene(){

		this.emitter.cleanup();
		this.emitter.destroy();
		this.emitter= null;

		this.bg2_sp.alpha=1;
		this.corps_sp.alpha=1;
		this.tete_sp.alpha=1;
		this.bg_sp.alpha=0;

		if(AppDatas.isTablet){
			TweenMax.to(this.zoomFilter, .8, { blur: 0, ease: Power3.easeOut, onComplete: () => this.animOut()})
		}else{
			TweenMax.to(this.zoomFilter, .8, { strength: 0, ease: Power3.easeOut, onComplete: () => this.animOut()})
		}

		TweenMax.to(this.tete_sp, .6, { rotation: GraphicsUtils.degreeToRadian(20), y:AppDatas.GAME_HEIGHT * .5 - 150, delay:.8, ease: Power3.easeIn})

	}

	updateParticles(){
		if(this.emitter){
			requestAnimationFrame(() => this.updateParticles());
			var now = Date.now();
			this.emitter.update((now - this.elapsed) * 0.001); 
			this.elapsed = now;
		}
		
	}


	animOut(){

		this.cont.filters = [
			this.displacementFilter,
		];


		TweenMax.to(this.displacementFilter.scale, 4, { x: 100, y: 500, delay:1, ease: Power2.easeIn, onComplete: () => this.destroy()})
		TweenMax.to(this.cont, 2.5, { alpha: 0, delay:2, ease: Power1.easeIn })
	}

	destroy(){

		this.cont.destroy({children:true, texture:false, baseTexture:true});
		AppDatas.appView.navigateTo("enquete2_2_slides");

	}

}
